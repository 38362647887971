import { IonContent, IonPage, IonImg, useIonViewDidLeave } from '@ionic/react';
import { useContext, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { PdfContext } from '../components/PdfViewer';
import { LanguageContext } from '../components/LanguageSelect';

const AppAcademy = () => {
  const { language } = useContext(LanguageContext);
  const { setModalVisible, setDesktopPdf, setMobilePdf } = useContext(PdfContext);
  const [swiper, setSwiper] = useState<SwiperCore>();
  // set the swiper slide to the first one when the component is mounted
  useIonViewDidLeave(() => {
    swiper?.slideTo(0);
  }, [swiper]);
  return (
    <>
      <IonPage>
        <IonContent fullscreen>
          <Swiper
            initialSlide={0}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            onSwiper={(swiper) => setSwiper(swiper)}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            spaceBetween={0}
            centeredSlides={true}
            breakpoints={{
              768: {
                slidesPerView: 1.2,
                spaceBetween: -20
              },
              1024: {
                slidesPerView: 1.4,
                spaceBetween: -120
              },
            }}
          >
            <SwiperSlide>
              <div className="container-inner app-academy">
                <div className="d-flex">
                  <h1 className='container-title' style={{ background: '#9c00ff', color: '#fff' }}>{
                    language === 'id' ? `Kelas Aplikasi` :
                      language === 'vn' ? `Học viện ứng dụng` :
                        language === 'th' ? `แอพอะคาเดมี่` :
                          `App Academy`}</h1>
                  <div className='container-content' style={{ background: '#fff', color: '#303030', paddingTop: '0.1rem' }}>
                    <p>{
                      language === 'id' ? `Akademi Aplikasi membantu kamu untuk lebih paham cara menggunakan Tinder secara lebih baik. Akademi ini akan memberi tahu semua fitur keren yang mungkin kamu lewatkan (yup, menyukai dan bisa cocok sama orang dari negara yang berbeda itu ada fiturnya) dan juga tentang fitur keren Jelajah. Setelah kamu menjelajah sesi ini, jangan kaget kalau banyak match baru yang bisa kamu dapatkan!` :
                        language === 'vn' ? `Học viện ứng dụng đảm bảo rằng bạn biết cách sử dụng Tinder cũng như hiểu rõ trái
                        tim mình muốn gì. Học viện ứng dụng giới thiệu tới bạn tất cả những tính năng thú vị mà bạn có
                        thể đã bỏ lỡ (đúng vậy, việc thích và tương hợp từ một quốc gia khác hoàn toàn là một vấn đề
                        quan trọng) cùng với tính năng mới hấp dẫn nhất trên ứng dụng của chúng tôi - tính năng Khám phá. Sau khi lướt qua phần này, đừng ngạc nhiên vì đảm bảo bạn sẽ bỏ túi thêm hàng loạt tương hợp` :
                          language === 'th' ? `แอพอะคาเดมี่จะช่วยให้คุณรู้จักการใช้  Tinder ให้ดีขึ้นพอ ๆ กับที่คุณรู้จักการค้นหาหัวใจตัวเอง แอพอะคาเดมี่จะพาคุณไปรู้จักกับฟีเจอร์เจ๋ง ๆ ทั้งหมดซึ่งคุณอาจจะไม่เคยรู้ว่ามีมาก่อน เช่น การ Like และการจับคู่ Match กับคนที่อยู่ต่างประเทศ พร้อมกับการพาทัวร์ชมหน้า Explore บนแอพ Tinder บอกเลยว่าหลังจากที่คุณได้เข้าไปอ่านดูแล้วก็ไม่ต้องแปลกใจเลย ถ้าคุณจะมีคู่ Match เพิ่มมากขึ้น` :
                            `The App Academy makes sure you know your way around Tinder just as well as you know your way around hearts. It walks you through all the cool features that you may have missed (yup, liking and matching from a different country is totally a thing) along with a tour of our hottest new venue in-app - Explore. After browsing through this section, don’t be surprised if you’re collecting matches left and right!`
                    }
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
            <div className="container-inner design-video">
              <div className="d-flex">
                <h1 className='container-title'>Heading of the video</h1>
                <div className='container-content'>
                  <div className="video-container">
                    <div className="thumbnail">
                      <IonImg src='assets/img/video-thumb.png'></IonImg>
                    </div>
                    <div className="description-outer">
                      <div className="description">
                        <h3 className='title'>
                          Video title
                        </h3>
                        <p>Video subtitle, this is a dummy description, lorem ipsum dolor sit amet.</p>
                      </div>
                      <a href="/">CLICK TO VIEW</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide> */}
            <SwiperSlide>
              <div className="container-inner design-one">
                <div className="d-flex">
                  <h1 className='container-title' style={{ background: '#ff00ae', color: '#fff' }}>{language === 'id' ? `Bikin profil kamu glow-up` : language === 'vn' ? `Hồ sơ cá nhân` : language === 'th' ? `โปรเจกต์การสร้างโปรไฟล์` : `The Profile Project`}</h1>
                  <div className='container-content' style={{ background: '#fff', color: '#303030' }}>
                    <p className='hide-desktop' >{language === 'id' ? `Solusi dari setiap permasalahan profil kamu - dari memilih foto sampai menulis bio yang tepat! ` : language === 'vn' ? `Giải pháp cho mọi vấn đề về hồ sơ Tinder - từ việc chọn ảnh phù hợp cho đến lời giới thiệu hoàn hảo!` : language === 'th' ? `ทางออกของทุกปัญหาบนหน้าโปรไฟล์ ตั้งแต่การเลือกรูปสุดปั๊วะ ไปจนถึงการสร้างประวัติส่วนตัวสุดปัง` : `Your solution to every profile problem - from picking pictures that werk to the perfect bio!`}</p>
                    <IonImg src={
                      language === 'id' ? `assets/img/id-aa-profile-pro-thumb-01.png` :
                        language === 'vn' ? `assets/img/vn-aa-profile-pro-thumb-01.png` :
                          language === 'th' ? `assets/img/th-aa-profile-pro-thumb-01.png` :
                            `assets/img/aa-profile-pro-thumb-01.png`}></IonImg>
                    <div className='flex'>
                      <p className='hide-mobile' style={{ maxWidth: '550px' }}>{language === 'id' ? `Solusi dari setiap permasalahan profil kamu - dari memilih foto sampai menulis bio yang tepat!` : language === 'vn' ? `Giải pháp cho mọi vấn đề về hồ sơ Tinder - từ việc chọn ảnh phù hợp cho đến lời giới thiệu hoàn hảo!` : language === 'th' ? `ทางออกของทุกปัญหาบนหน้าโปรไฟล์ ตั้งแต่การเลือกรูปสุดปั๊วะ ไปจนถึงการสร้างประวัติส่วนตัวสุดปัง` : `Your solution to every profile problem - from picking pictures that werk to the perfect bio!`}</p>
                      <button style={{ color: "#fff", background: "#ff00ae" }} onClick={() => {
                        setDesktopPdf(language === 'id' ? `/assets/pdf/ID_TheProfileProject.pdf` : language === 'vn' ? `/assets/pdf/VN_TheProfileProject.pdf` : language === 'th' ? `/assets/pdf/TH_TheProfileProject.pdf` : `/assets/pdf/TheProfileProject.pdf`);
                        setMobilePdf(language === 'id' ? `/assets/pdf/ID_TheProfileProject(m).pdf` : language === 'vn' ? `/assets/pdf/VN_TheProfileProject(m).pdf` : language === 'th' ? `/assets/pdf/TH_TheProfileProject(m).pdf` : `/assets/pdf/TheProfileProject(m).pdf`);
                        setModalVisible(true);
                      }}>{language === 'id' ? `Yuk, jadi profile-pro` : language === 'vn' ? `Trở thành chuyên gia` : language === 'th' ? `สร้างโปรไฟล์แบบมือโปร` : `Become a Pro`}</button>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="container-inner design-one">
                <div className="d-flex">
                  <h1 className='container-title' style={{ background: '#26e8c8', color: '#fff' }}>{
                    language === 'id' ? `Tentang Jelajah` :
                      language === 'vn' ? `Giải thích về Tính năng Khám phá` :
                        language === 'th' ? `ทำความรู้จัก` :
                          `Explore Explained`}</h1>
                  <div className='container-content' style={{ background: '#fff', color: '#303030' }}>
                    <p className='hide-desktop'>{
                      language === 'id' ? `Pilih minatmu dan bertemu dengan mereka yang sama dengan kamu.` :
                        language === 'vn' ? `Lựa chọn sở thích, đam mê của bạn và kết nối với những người cùng gu.` :
                          language === 'th' ? `เลือกแพสชั่นของคุณ เพื่อ Match กับคนที่ใช่` :
                            `Pick your passion and connect with people who match your vibe.`}</p>
                    <IonImg src={
                      language === 'id' ? `assets/img/id-aa-explore-thumb-01.png` :
                        language === 'vn' ? `assets/img/vn-aa-explore-thumb-01.png` :
                          language === 'th' ? `assets/img/th-aa-explore-thumb-01.png` :
                            `assets/img/aa-explore-thumb-01.png`}></IonImg>
                    <div className='flex'>
                      <p className='hide-mobile' style={{ maxWidth: '550px' }}>{
                        language === 'id' ? `Pilih minatmu dan bertemu dengan mereka yang sama dengan kamu.` :
                          language === 'vn' ? `Lựa chọn sở thích, đam mê của bạn và kết nối với những người cùng gu.` :
                            language === 'th' ? `เลือกแพสชั่นของคุณ เพื่อ Match กับคนที่ใช่` :
                              `Pick your passion and connect with people who match your vibe.`}</p>
                      <button onClick={() => {
                        setDesktopPdf(language === 'id' ? `/assets/pdf/ID_Explore.pdf` : language === 'vn' ? `/assets/pdf/VN_Explore.pdf` : language === 'th' ? `/assets/pdf/TH_Explore.pdf` : `/assets/pdf/Explore.pdf`);
                        setMobilePdf(language === 'id' ? `/assets/pdf/ID_Explore(m).pdf` : language === 'vn' ? `/assets/pdf/VN_Explore(m).pdf` : language === 'th' ? `/assets/pdf/TH_Explore(m).pdf` : `/assets/pdf/Explore(m).pdf`);
                        setModalVisible(true);
                      }} style={{ color: "#fff", background: "#26e8c8" }}>{language === 'id' ? `Jelajah bareng yuk!` : language === 'vn' ? `Bắt đầu khám phá` : language === 'th' ? `เริ่มกันเลย` : `Start Explore-ing`}</button>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="container-inner design-one">
                <div className="d-flex">
                  <h1 className='container-title' style={{ background: '#298cfb', color: '#fff' }}>{
                    language === 'id' ? `Kenalan dengan Fitur Tinder` :
                      language === 'vn' ? `Các tính năng nổi bật trên Tinder` :
                        language === 'th' ? `ฟีเจอร์ที่อยากอวด` :
                          `Flexin’ Our Features`}</h1>
                  <div className='container-content' style={{ background: '#fff', color: '#303030' }}>
                    <p className='hide-desktop'>{
                      language === 'id' ? `Fitur handal yang bakal bikin pengalaman kamu di Tinder jadi makin seru.` :
                        language === 'vn' ? `Những tính năng hấp dẫn nhất chắc chắn mang tới cho bạn một trải nghiệm Tinder thú vị bậc nhất.` :
                          language === 'th' ? `ฟีเจอร์สุดฮ็อตที่จะช่วยให้คุณมีประสบการณ์สุดคูลในการใช้ Tinder` :
                            `The hottest features that make sure you have the coolest Tinder experience.`}</p>
                    <IonImg src={
                      language === 'id' ? `assets/img/id-aa-flex-thumb-01.png` :
                        language === 'vn' ? `assets/img/vn-aa-flex-thumb-01.png` :
                          language === 'th' ? `assets/img/th-aa-flex-thumb-01.png` :
                            `assets/img/aa-flex-thumb-01.png`} style={{ objectPosition: 'bottom' }}></IonImg>
                    <div className='flex'>
                      <p className='hide-mobile' style={{ maxWidth: '550px' }}>{
                        language === 'id' ? `Fitur handal yang bakal bikin pengalaman kamu di Tinder jadi makin seru.` :
                          language === 'vn' ? `Những tính năng hấp dẫn nhất chắc chắn mang tới cho bạn một trải nghiệm Tinder thú vị bậc nhất.` :
                            language === 'th' ? `ฟีเจอร์สุดฮ็อตที่จะช่วยให้คุณมีประสบการณ์สุดคูลในการใช้ Tinder` :
                              `The hottest features that make sure you have the coolest Tinder experience.`}</p>
                      <button onClick={() => {
                        setDesktopPdf(language === 'id' ? `/assets/pdf/ID_Features.pdf` : language === 'vn' ? `/assets/pdf/VN_Features.pdf` : language === 'th' ? `/assets/pdf/TH_Features.pdf` : `/assets/pdf/Features.pdf`);
                        setMobilePdf(language === 'id' ? `/assets/pdf/ID_Features(m).pdf` : language === 'vn' ? `/assets/pdf/VN_Features(m).pdf` : language === 'th' ? `/assets/pdf/TH_Features(m).pdf` : `/assets/pdf/Features(m).pdf`);
                        setModalVisible(true);
                      }} style={{ color: "#fff", background: "#298cfb" }}>{
                          language === 'id' ? `Cek lebih lanjut!` :
                            language === 'vn' ? `Khám phá ngay` :
                              language === 'th' ? `ไปดูกันเลย` :
                                `Check Them Out`}</button>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="container-inner design-one">
                <div className="d-flex">
                  <h1 className='container-title' style={{ background: '#ff5599', color: '#fff' }}>{language === 'id' ? `Cara Menghindari Obrolan Yang Garing` : language === 'vn' ? `Làm thế nào để rắc muối cho cuộc trò chuyện thêm lôi cuốn` : language === 'th' ? `วิธีเลี่ยงการคุยแบบจืดชืด` : `How To Avoid Dry Convos`}</h1>
                  <div className='container-content' style={{ background: '#fff', color: '#303030' }}>
                    <p className='hide-desktop'>{language === 'id' ? `Kuasai ice-breaking dan bikin percakapan seru - tingkatkan kemampuan ngobrol kamu dengan tips-tips ini!` : language === 'vn' ? `Hãy trở thành người phá băng và mở đầu cuộc trò chuyện. Nâng cấp kỹ năng trò chuyện của bạn với những gợi ý thú vị này nhé!` : language === 'th' ? `มาเป็นฝ่ายทักครัช อัพสกิลการแชท เริ่มคุยก่อนด้วยคำแนะนำของเรา` :
                      `Become the ice-breaker and conversation maker - level up your texting game with these pointers!`}</p>
                    <IonImg src={language === 'id' ? `assets/img/id-aa-dry-convos-thumb-01.png` : language === 'vn' ? `assets/img/vn-aa-dry-convos-thumb-01.png` : language === 'th' ? `assets/img/th-aa-dry-convos-thumb-01.png` : `assets/img/aa-dry-convos-thumb-01.png`}></IonImg>
                    <div className='flex'>
                      <p className='hide-mobile' style={{ maxWidth: '550px' }}>{language === 'id' ? `Kuasai ice-breaking dan bikin percakapan seru - tingkatkan kemampuan ngobrol kamu dengan tips-tips ini! ` : language === 'vn' ? `Hãy trở thành người phá băng và mở đầu cuộc trò chuyện. Nâng cấp kỹ năng trò chuyện của bạn với những gợi ý thú vị này nhé!` : language === 'th' ? `มาเป็นฝ่ายทักครัช อัพสกิลการแชท เริ่มคุยก่อนด้วยคำแนะนำของเรา` : `Become the ice-breaker and conversation maker - level up your texting game with these pointers!`}</p>
                      <button onClick={() => {
                        setDesktopPdf(language === 'id' ? `/assets/pdf/ID_AvoidDryConvo.pdf` : language === 'vn' ? `/assets/pdf/VN_AvoidDryConvo.pdf` : language === 'th' ? `/assets/pdf/TH_AvoidDryConvo.pdf` : `/assets/pdf/AvoidDryConvo.pdf`);
                        setMobilePdf(language === 'id' ? `/assets/pdf/ID_AvoidDryConvo(m).pdf` : language === 'vn' ? `/assets/pdf/VN_AvoidDryConvo(m).pdf` : language === 'th' ? `/assets/pdf/TH_AvoidDryConvo(m).pdf` : `/assets/pdf/AvoidDryconvo(m).pdf`);
                        setModalVisible(true);
                      }} style={{ color: "#fff", background: "#ff5599" }}>{
                          language === 'id' ? `Mulai mengetik` :
                            language === 'vn' ? `Bắt đầu nhắn tin` :
                              language === 'th' ? `เริ่มแชทกันเลย` :
                                `Start Typing`}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper >
        </IonContent >
      </IonPage >
    </>
  );
};

export default AppAcademy;