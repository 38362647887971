import { IonContent, IonPage } from '@ionic/react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '../components/LanguageSelect';
// import Container from '../components/Container';

const Home: React.FC = () => {
  const history = useHistory();
  const { language } = useContext(LanguageContext);
  const btnHandler = () => {
    // route to /app-academy
    history.push("/app-academy");
  }
  return (
    <IonPage className='home'>
      <IonContent fullscreen className='container'>
        <h1>{
          language === 'id' ? 'Apa sih kerennya School of Swipe™?' : language === 'th' ? 'เรื่องตื่นเต้นในคลาสเรียน แห่งการปัดขวา™?' : language === 'vn' ? 'School of Swipe™ có gì thú vị?' : 'What’s the hype around School of Swipe™?'
        }
        </h1>
        <p style={{ lineHeight: '1.2' }}>{
          language === 'id' ? `Yuk kenali definisi swipe (geser), match (jodoh), ghosting, sampai thirsting, dan banyak hal lainnya, ada berbagai ilmu berkencan lainnya yang bisa kamu pelajari. Selangkah lebih maju dan tingkatkan wawasanmu supaya semakin mantap ngedeketin si dia.\nSudah siap belajar berkencan? Yuk, kelasnya sudah mulai.` :
            language === 'vn' ? `Từ vuốt trái và phải, tương hợp, ghosting, đến thả thính và hơn thế nữa, mỗi bước đi trong hành trình hẹn hò là một bài học. Sẵn sàng dẫn đầu xu thế và nâng cấp các kỹ năng của bạn để chinh phục đối phương ngay thôi.\nBạn cần một khóa học hẹn hò? Bắt đầu lớp học ngay thôi.` :
              language === 'th' ? `บทเรียนการออกเดทตั้งแต่เริ่มจับคู่ Match หรือโดนเทแบบงง ๆ
            ไปจนถึงการจีบแบบมือโปร เรียนรู้เคล็ดลับดี ๆ
            เพื่ออัพสกิลการเดทให้ล้ำหน้ากว่าใคร
            และเพิ่มทักษะการใช้แอพเตรียมพร้อมก่อนปัดขวา ทักไอ้ต้าวน่ารักที่ถูกใจ
            อยากเรียนรู้บทเรียนการออกเดทแล้วใช่มั้ย? ตอนนี้มีคลาสสอนอยู่นะ` : 'With all the sliding, matching, ghosting, thirsting and so much more, there’s a dating lesson around every corner. Get ahead of the curve and level up your skills so that you’re fully prepped to hit up that cutie.\nNeed a dating lesson? Class is in session.'
        }</p>
        <button className='white-btn' onClick={() => btnHandler()} style={{ zIndex: '105', marginTop: '1rem' }}>{
          language === 'id' ? `Ayo!` :
            language === 'vn' ? `Cùng bắt đầu nào` :
              language === 'th' ? `ไปเรียนกันเลย` : 'Let’s go'
        }</button>
      </IonContent>
    </IonPage>
  );
};

export default Home;