import { Fragment } from "react"
import "./style.css";

interface props {
    tabname: String,
    tabDesc: String[],
    accordianItems: JSX.Element[]
}
const Tabs = (props: props) => {
    return <>
        <div className="ribbon-content max-container-size">
            {props.tabDesc.map((para, index) => {
                return (
                    <div key={index}>
                        <p>
                            {para}
                        </p>
                    </div>
                );
            })}
        </div>
        {
            props.accordianItems &&
            <div className="accordian max-container-size">
                {
                    props.accordianItems.map((accordianItem, index) => <Fragment key={index}>{accordianItem}</Fragment>)
                }

            </div>
        }
    </>
}
export default Tabs;