import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import { IonSelect, IonSelectOption } from "@ionic/react";
import { useLocation } from "react-router";

export enum LanguageEnum {
  EN = "en",
  ID = "id",
  TH = "th",
  VN = "vn",
}

type LanguageContextType = {
  language: LanguageEnum;
  setLanguage: (value: LanguageEnum) => void;
};

export const LanguageContext = createContext<LanguageContextType>({
  language: LanguageEnum.EN, // Default language set to English
  setLanguage: () => {},
});

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const location = useLocation();
  const [language, setLanguage] = useState<LanguageEnum>(LanguageEnum.EN);

  // Check for the lang param in the query and update language state if valid
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const langParam = params.get("lang") as LanguageEnum;

    if (langParam && Object.values(LanguageEnum).includes(langParam)) {
      setLanguage(langParam);
    }
  }, [location.search]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export function LanguageSelect() {
  const { language, setLanguage } = useContext(LanguageContext);

  return (
    <>
      <IonSelect
        interface="popover"
        value={language}
        onIonChange={(e) => setLanguage(e.detail.value as LanguageEnum)}
        style={{ position: "absolute", right: "1rem", zIndex: 99 }}
      >
        <IonSelectOption value={LanguageEnum.EN}>English</IonSelectOption>
        <IonSelectOption value={LanguageEnum.ID}>Bahasa</IonSelectOption>
        <IonSelectOption value={LanguageEnum.TH}>Thai</IonSelectOption>
        <IonSelectOption value={LanguageEnum.VN}>Vietnamese</IonSelectOption>
      </IonSelect>
    </>
  );
}
