import React, { useContext, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonHeader, IonToolbar, IonTitle, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, IonImg, IonGrid, IonIcon } from '@ionic/react';
import Home from '../pages/home';
import AppAcademy from '../pages/app-academy';
import SafetySyllabus from '../pages/safety-syllabus';
import IRLDating from '../pages/irl-dating';
import { chevronBack, chevronForward } from 'ionicons/icons';
import { LanguageContext, LanguageSelect } from './LanguageSelect';

export const Header: React.FC = () => {
    let [zIndex, setZIndex] = useState('99');
    const { language } = useContext(LanguageContext);
    setTimeout(() => {
        setZIndex('101');
    }, 3000);

    return (
        <>
            <IonGrid className='header' style={{ zIndex: zIndex }}>
                <LanguageSelect />
                <IonHeader className="ion-no-border ion-padding ion-justify-content-center" mode="md">
                    <IonToolbar>
                        <IonImg src='assets/img/logo.png' className='header-logo' onClick={() => { window.location.href = "/" }}></IonImg>
                    </IonToolbar>
                </IonHeader>
                <IonTabs className='header-nav'>
                    <IonRouterOutlet>
                        <Route path="/home">
                            <Home />
                        </Route>
                        <Route path="/app-academy">
                            <AppAcademy />
                        </Route>
                        <Route path="/safety-syllabus">
                            <SafetySyllabus />
                        </Route>
                        <Route path="/irl-dating">
                            <IRLDating />
                        </Route>
                        <Route exact path="/">
                            <Redirect to="/home" />
                        </Route>
                    </IonRouterOutlet>
                    <IonTabBar slot="top" mode="md" className='header-nav-bar'>
                        <IonTabButton tab="home" className='no-ripple' href="/home">
                            <IonTitle color="light">{language === 'id' ? 'beranda' : language === 'th' ? 'หน้าหลัก' : language === 'vn' ? 'trang chủ' : 'home'}</IonTitle>
                        </IonTabButton>
                        <IonTabButton tab="app-academy" href="/app-academy" className='no-ripple'>
                            <IonTitle color="light">{language === 'id' ? 'KELAS APLIKASI' : language === 'th' ? 'แอพอะคาเดมี่' : language === 'vn' ? 'Học viện ứng dụng' : 'app academy'}</IonTitle>
                        </IonTabButton>
                        <IonTabButton tab="dating-fest" href="/irl-dating" className='no-ripple'>
                            <IonTitle color="light">{language === 'id' ? 'KENCAN IRL 101' : language === 'th' ? 'วิธีการออกเดท 101' : language === 'vn' ? 'Hẹn hò trực tiếp 101' : 'irl dating 101'}</IonTitle>
                        </IonTabButton>
                        <IonTabButton tab="safety-syllabus" href="/safety-syllabus" className='no-ripple'>
                            <IonTitle color="light">{language === 'id' ? 'SILABUS KEAMANAN' : language === 'th' ? 'หลักสูตรความปลอดภัย' : language === 'vn' ? 'Khóa học An toàn' : 'safety syllabus'}</IonTitle>
                        </IonTabButton>
                    </IonTabBar>
                </IonTabs>
                <IonIcon icon={chevronBack} className="navbar-chevron-left"></IonIcon>
                <IonIcon icon={chevronForward} className="navbar-chevron-right"></IonIcon>
            </IonGrid>
        </>
    );
}