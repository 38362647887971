import { IonContent, IonPage, IonImg, useIonViewDidLeave } from '@ionic/react';
import { useContext, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { PdfContext } from '../components/PdfViewer';
import { LanguageContext } from '../components/LanguageSelect';

const IRLDating: React.FC = () => {
  const { language } = useContext(LanguageContext);
  const { setModalVisible, setDesktopPdf, setMobilePdf } = useContext(PdfContext);
  const [swiper, setSwiper] = useState<SwiperCore>();
  // set the swiper slide to the first one when the component is mounted
  useIonViewDidLeave(() => {
    swiper?.slideTo(0);
  }, [swiper]);
  return (
    <>
      <IonPage>
        <IonContent fullscreen>
          <Swiper
            initialSlide={0}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            onSwiper={(swiper) => setSwiper(swiper)}
            slidesPerView={1}
            // loop={true}
            navigation
            pagination={{ clickable: true }}
            spaceBetween={0}
            centeredSlides={true}
            breakpoints={{
              768: {
                slidesPerView: 1.2,
                spaceBetween: -20
              },
              1024: {
                slidesPerView: 1.4,
                spaceBetween: -120
              },
            }}
          >
            <SwiperSlide>
              <div className="container-inner">
                <div className="d-flex">
                  <h1 className='container-title' style={{ background: '#00ff7e', color: '#fff' }}>{
                    language === 'id' ? `Kencan IRL 101` :
                      language === 'vn' ? `Hẹn hò trực tiếp 101` :
                        language === 'th' ? `วิธีการออกเดท 101` :
                          `IRL Dating 101`}</h1>
                  <div className='container-content' style={{ background: '#fff', color: '#303030', paddingTop: '0.1rem' }}>
                    <p>
                      {
                        language === 'id' ? `Ini kelas buat kalian yang sudah pro di online dating tapi masih suka panik saat harus ketemuan langsung. Ini cara gampangnya gimana menghadapi transisi hubungan dari online ke dunia nyata dengan santai, nggak pakai cemas!` :
                          language === 'vn' ? `Thông tin dành cho tất cả những ai đã có một cuộc trò chuyện thú vị qua mạng nhưng vẫn chưa biết phải làm gì tiếp theo khi bắt đầu chuyển sang gặp gỡ đối phương ngoài đời thực. Những hướng dẫn dưới đây sẽ giúp bạn dễ dàng chuyển từ trò chuyện trực tuyến sang gặp mặt trực tiếp một cách thoải mái mà không tạo ra bất kỳ lo lắng, áp lực nào!` :
                            language === 'th' ? `สำหรับคนที่กล้าออกเดทออนไลน์ แต่กลับกลัวจนสติหลุดเมื่อต้องออกเดทแบบออฟไลน์ นี่คือวิธีง่ายๆ ที่เราอยากแนะนำในการเปลี่ยนจากการเดทออนไลน์ไปเป็นเดทแบบนัดพบในชีวิตจริงให้ราบรื่นไร้กังวล` :
                              `This one’s for all of you who cracked the code to online dating but then low-key freak out when it starts shifting offline. Here’s an easy walkthrough on how to make the smooth transition from online to IRL in a chill, anxiety-free way!`}
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
            <div className="container-inner design-video">
              <div className="d-flex">
                <h1 className='container-title'>Heading of the video</h1>
                <div className='container-content'>
                  <div className="video-container">
                    <div className="thumbnail">
                      <IonImg src='assets/img/video-thumb.png'></IonImg>
                    </div>
                    <div className="description-outer">
                      <div className="description">
                        <h3 className='title'>
                          Video title
                        </h3>
                        <p>Video subtitle, this is a dummy description, lorem ipsum dolor sit amet.</p>
                      </div>
                      <a href="/">CLICK TO VIEW</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide> */}
            <SwiperSlide>
              <div className="container-inner design-one">
                <div className="d-flex">
                  <h1 className='container-title' style={{ background: '#26e8c8', color: '#fff' }}>{
                    language === 'id' ? `Kelas Pertama - Kencan Pertama` :
                      language === 'vn' ? 'Bài học vỡ lòng: Buổi hẹn đầu tiên' :
                        language === 'th' ? 'คลาสแรกกับเดทแรก' :
                          `First Class: First Dates`}</h1>
                  <div className='container-content' style={{ background: '#fff', color: '#303030' }}>
                    <p className='hide-desktop'>{
                      language === 'id' ? 'Hilangkan kegugupan kamu dengan tips kencan pertama anti gagal!' :
                        language === 'vn' ? 'Tạm biệt những lo lắng bằng những lời khuyên bất bại cho buổi hẹn đầu tiên!' :
                          language === 'th' ? 'ส่งจูบบอกลา บ๊ายบายความกังวลทั้งหลาย ด้วยเคล็ดลับเดทแรกแบบเน้นๆ ไม่มีพลาด' :
                            `Kiss those nerves goodbye with these first date tips that never fail!`}</p>
                    <IonImg src={
                      language === 'id' ? `assets/img/id-irl-fcfc-thumb-01.png` :
                        language === 'vn' ? `assets/img/vn-irl-fcfc-thumb-01.png` :
                          language === 'th' ? `assets/img/th-irl-fcfc-thumb-01.png` :
                            `assets/img/irl-fcfc-thumb-01.png`}></IonImg>
                    <div className='flex'>
                      <p className='hide-mobile' style={{ maxWidth: '550px' }}>{
                        language === 'id' ? 'Hilangkan kegugupan kamu dengan tips kencan pertama anti gagal!' :
                          language === 'vn' ? 'Tạm biệt những lo lắng bằng những lời khuyên bất bại cho buổi hẹn đầu tiên!' :
                            language === 'th' ? 'ส่งจูบบอกลา บ๊ายบายความกังวลทั้งหลาย ด้วยเคล็ดลับเดทแรกแบบเน้นๆ ไม่มีพลาด' :
                              `Kiss those nerves goodbye with these first date tips that never fail!`}</p>
                      <button onClick={() => {
                        setDesktopPdf(
                          language === 'id' ? '/assets/pdf/ID_FirstClassFirstDates.pdf' :
                            language === 'vn' ? '/assets/pdf/VN_FirstClassFirstDates.pdf' :
                              language === 'th' ? '/assets/pdf/TH_FirstClassFirstDates.pdf' :
                                `/assets/pdf/FirstClassFirstDates.pdf`);
                        setMobilePdf(
                          language === 'id' ? '/assets/pdf/ID_FirstClassFirstDates(m).pdf' :
                            language === 'vn' ? '/assets/pdf/VN_FirstClassFirstDates(m).pdf' :
                              language === 'th' ? '/assets/pdf/TH_FirstClassFirstDates(m).pdf' :
                                `/assets/pdf/FirstClassFirstDates(m).pdf`);
                        setModalVisible(true);
                      }} style={{ color: "#fff", background: "#26e8c8" }}>{
                          language === 'id' ? 'Kencan yuk' :
                            language === 'vn' ? 'Hẹn hò thôi nào' :
                              language === 'th' ? 'ไปเดทกัน' :
                                `Let’s Date`}</button>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </IonContent>
      </IonPage>
    </>
  );
};

export default IRLDating;
