import { IonImg } from '@ionic/react';
interface data {
    isActive?: boolean,
    accordianTitle: string,
    accordianDesc: string,
    longTitle: string,
    longDesc: string[],
    columns: {
        columnClass: string,
        columnHeading: string,
        columnImage: string,
        columnData: string[]
    }[],
    endPara: string[]
    HtmlEntity?: JSX.Element,

}

const HtmlEntityData = () => {
    return <div>
        <h3 className="flex-in-our-features-center-heading transparent-heading">Safety Over Chat</h3>
        <div className="columns">
            <div className="column-default column-1 max-width-500">
                <div className="column-img"></div>
                <h2>Protect What’s Yours</h2>
                <p>Never share ANYONE’S personal information with people you don’t know. Your personal identifying information (PII), address, and details about your daily routine (e.g., that you go to a certain gym every Monday) along with any info about your family and friends should be kept private 🙅‍♀️</p>
            </div>
            <div className="column-default column-1">
                <div className="column-img"><IonImg src="assets/img/au_SafetySeminar/au_VectorSmartObject9.png" /></div>
                <h2>Stick to Tinder</h2>
                <p>Getting to know someone new? Staying on the Tinder platform is a great (and safe) idea! We only allow texts, emojis and video calls so don’t worry about receiving any unwanted pictures.  Be cautious if your match tries to move the conversation to phone calls or other apps right away - they probably have bad intentions and aim to bypass Tinder’s Safe Message Filters. (that’s a major red flag 🚩)</p>
            </div>

            <div className="column-default column-6">
                <div className="column-img"><IonImg src="assets/img/au_SafetySeminar/au_VectorSmartObject8.png" /></div>
                <h2>Keep it Secure</h2>
                <div>
                    <p>Your Tinder password should be stronger than the chemistry with your match and that’s saying something. 💪🏻 Make sure you’re careful when logging in from a public/shared computer and beware of any Tinder emails that ask for your username and password information (we wouldn’t send emails like this).— if you receive an email asking for account info, report it immediately! You can find details on how to write to us to report this type of behaviour in the in-app safety centre.</p>
                </div>
            </div>
            <div className="column-default column-1">
                <div className="column-img"><IonImg src="assets/img/au_SafetySeminar/au_VectorSmartObject7.png" /></div>
                <h2>Be Wary of Scams</h2>
                <p>Watch out for scammers who ask for financial help and anyone who will not meet in person or talk on a phone/video call—they may not be who they say they are. If someone is avoiding your questions or pushing for a serious relationship without meeting or getting to know you first, that’s probably a red flag! 👎</p>
            </div>
        </div>
        <br />
        <br />
        <h3 className="flex-in-our-features-center-heading transparent-heading">Be Careful IRL</h3>

        <div className='columns'>
            <div className='column-default column-1 padding'>
                <div>
                    <IonImg src="assets/img/au_SafetySeminar/au_VectorSmartObject6.png"></IonImg>
                </div>
                <div>
                    <h2>Don’t Rush It!</h2>
                    <div>
                        <p>Before you shift things to IRL, take your time and get to know the other person. Want to snuff out the red flags? Don’t be afraid to ask questions or get on a phone/ video call to screen your match before meeting them! 📱</p>
                        <p>Tip: Meet in a populated, public place! Good Spots: Happening bars, good restaurants or chill cafes. Bad Spots: Your home, your date’s home or any secluded or private location.</p>
                    </div>
                </div>
            </div>

            <div className='column-default column-6 flex-row-reverse padding'>
                <div>
                    <IonImg src="assets/img/au_SafetySeminar/au_VectorSmartObject5.png" className='emojiImgs'></IonImg>
                </div>
                <div>
                    <h2>Be in Control and Have a Backup </h2>
                    <div>
                        <p>In case you need to make a quick exit, always have backups and be safe! Keep your people in the loop and tell a family member where you’re going and when. When in a taxi, always share your ride with a friend or family member, and if possible, ask a friend to pick you up! It’s also a good idea to keep your phone charged or carry a power backup, just in case. If you're in a bar or a restaurant and you don't feel safe, most bartenders or waitstaff will be happy to help you if you let them know.</p>
                        <p>Tip: If your date tries to insist on accompanying you when you’re not interested, say no and hold your ground or end the date. 🚫</p>
                    </div>
                </div>
            </div>

            <div className='column-default column-1 padding'>
                <div>
                    <IonImg src="assets/img/au_SafetySeminar/au_VectorSmartObject3.png" className='emojiImgs'></IonImg>
                </div>
                <div>
                    <h2>Beware drink spiking</h2>
                    <div>
                        <p>Be aware of drink spiking and keep track of your drink at all times! 🍺 Many substances that are slipped into drinks to facilitate sexual assault are odourless, colourless, and tasteless so only accept drinks poured or served directly by the bartender or server. It’s also good to know your limits and to never feel pressured, or pressure anyone else, into drinking more than you/they want to - both alcohol and drugs can impair your judgement and alertness. A common way people are spiked is through alcohol by someone giving them a double or triple shot when they think they're getting a single. Always tell someone if you purchase a double or triple shot drink.  Lastly, keep your phone, purse, wallet, and anything containing personal information on you at all times.</p>
                        <p>Tip  : If your date tries to pressure you to use drugs or drink more than you’re comfortable with, abort (the date) and report (the match)! If you’re at a bar or a club, it’s also useful to always get water from a running source (e.g. a tap)</p>
                    </div>
                </div>
            </div>

            <div className='column-default column-1 padding '>
                <div>
                    <IonImg src="assets/img/au_SafetySeminar/au_VectorSmartObject4.png" className='emojiImgs'></IonImg>
                </div>
                <div>
                    <h2>Comfort is Key</h2>
                    <div>
                        <p>Got a gut feeling that something isn’t right? Listen to your instincts and end the date early if you’re feeling uncomfortable. 🔚</p>
                        <p>Tip: If it’s challenging to end the date or you need some extra support, ask the bartender or server for help.</p>
                    </div>
                </div>
            </div>
        </div>
        <h3 className="flex-in-our-features-center-heading transparent-heading">Need some support? <br />Feel free to report!</h3>
        <div className='text-center padding'>
            <p>
                While most people have a positive experience, sadly not everyone has good intentions. When you come across something suss or when someone crosses the line, we want you to let us know and encourage you to unmatch, block and/or report anyone that breaches our terms.
            </p>
            <div className='heading-tinder-is-not-for'>
                <h2>⛔️ Tinder is not for:</h2>
                <p>Nudity/Sexual Content</p>
                <p>Requests for money or donations</p>
                <p>Underage users or impersonation</p>
                <p>Private Information Broadcasts - yours or anyone else’s.</p>
                <p>Harassment, hate speech threats, and offensive messages (both on and off the app)</p>
                <p>Violence or inappropriate and harmful behaviour during or after meeting in person</p>
                <p>Fraudulent profiles</p>
                <p>Spam or solicitation including links to commercial websites or attempts to sell products or services</p>
            </div>
            <p className='text-center padding'>
                If you breach any of these policies, you might be banned from Tinder and there will be no do-overs once we do. You heard that right: your offline behaviour can lead to the termination of your Tinder account.
            </p>
        </div>
        <div className='report-on-tinder'>
            <h2 className="flex-in-our-features-center-heading transparent-heading how-to-report">How to report on Tinder</h2>
            <div className='flex-col-2 padding'>
                <div>
                    <IonImg src="assets/img/au_SafetySeminar/au_VectorSmartObject.png"></IonImg>
                    <div>
                        <p>1. Click on the profile of the match you want to report. Click on the “Report” button to send us a quick, confidential report.</p>
                    </div>
                </div>
                <div>
                    <IonImg src="assets/img/au_SafetySeminar/au_VectorSmartObject2.png"></IonImg>
                    <div>
                        <p>2. From your message screen, click on the shield icon on the top right and then tap the following icon to send us a quick, confidential report. You can also use long press reporting to tap and hold offensive messages, which launches the reporting flow directly in the chat experience.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='text-center padding'>
            <p>
                Make sure you follow your instincts and keep your safety first in every situation, whether online or offline. Trust your gut and the minute something feels suss? Repeat after us - <u>Abort and Report! </u>
            </p>
        </div>
    </div>
}





const SafetySeminar: data = {
    accordianTitle: "Safety Seminar",
    accordianDesc: "Neither the chicken nor the egg, your safety comes first!",
    longTitle: "The Safety Seminar",
    longDesc: [
        "It’s always exciting to connect with new people that you vibe with but don’t forget to be careful, both on Tinder and IRL! Whether it’s texting or meeting up, here are some ways to keep your safety first through every step of your Tinder journey.",
        "Remember that you’re not responsible for other people’s behaviour and to report if something isn’t right, or you experience bad or inappropriate behaviour."
    ],
    columns: [],
    HtmlEntity: <HtmlEntityData />,
    endPara: []

}

const ConsentCrashCourse: data = {
    accordianTitle: "Consent Crash Course",
    accordianDesc: "A guide on how to navigate boundaries and communicate clearly!",
    longTitle: "The Consent Crash Course",
    longDesc: [
        "The online dating world is now more open than ever but before you dive in, it’s important to understand what your partner’s open to. Dating and sex can be difficult terrain to navigate and every person you meet will have their boundaries and expectations, but communication is key to cracking this course! 🔊",
        "Here’s everything you need to know about consent - whether it’s online or IRL. 💯"
    ],
    columns: [
        {
            columnClass: "column-5 padding",
            columnHeading: "What is Consent?",
            columnImage: "",
            columnData: [
                "It’s a pretty simple concept, really. Consent is to get permission for any form of online and IRL intimate activity, whether it’s hugging, touching, kissing, sex or even sexting. Consent should always be enthusiastic, compliance is not consent, and consent does not count if saying ‘no’ wasn’t a safe option. Consent can be expressed with actions but to be clear, it’s always safest to be verbal, especially as you are first getting to know someone! If you’re meeting someone online or in person, you have a responsibility to respect their boundaries and vice versa.",
                "Not sure what your match or partner is comfortable with? Always ask. 👍 Even if it may be clear by their actions, it’s always better to ask than assume. If you’re ready to take the next step, always make sure you receive consent before moving ahead - your match must be comfortable and actively consenting for any sexual activity to happen. Remember: Consent isn’t a one-and-done, it is dynamic and it needs to be covered at every step of the way! 🙌 Consent can be taken back at any time. This includes online, for example if someone has sent you an intimate photo and they ask you to delete it, failing to do so constitutes as image based abuse."
            ]
        },
        {
            columnClass: "column-6",
            columnHeading: "Asking for Consent ",
            columnImage: "assets/img/au_TheConsentCrashCourse/VectorSmartObject3.png",
            columnData: [
                "Whilst consent does not always have to be verbal, verbally agreeing to different sexual or intimate activities can help both you and your date respect each other’s boundaries.",
                "Verbal consent: It can be as simple as asking if you have consent and waiting for a clear and enthusiastic ‘yes’, or hearing out your date on what he or she wants. 🗣",
                "Non-verbal consent: Voluntary actions such as nodding ‘yes’, pulling someone closer,  mutually taking off clothes, or active engagement like mutual touching are non-verbal  signs of consent. 🫂 Remember, involuntary arousal signs DO NOT count as consent! ",
                "Take note: Non-verbal consent or cues tend to be less clear when you’re with a new partner, hence it’s always best to use verbal consent until you know someone well!",
                "Remember, the lack of ‘NO’ is not a ‘YES’. Consent should always be clear, enthusiastic, and ongoing throughout any form of intimate activity. Affirmative consent is where you’ve taken active steps to ensure the other person is consenting before any sexual activity takes place. In many states around Australia, affirmative consent laws have been rolled out which also means that not receiving an unforced ‘yes’ constitutes as sexual assault.",
                "Do not ignore your date or partner when they say ‘NO’ for any reason. 🙅‍♀️",
                "Avoid making assumptions about your date or partner’s interest purely based on their consent to a different activity or in the past.",
                "People who are incapacitated with drugs or alcohol cannot consent. Unequal power dynamics, such as engaging in an activity with an employee or student, also mean that consent cannot be freely given."

            ]
        },
        {
            columnClass: "column-1",
            columnHeading: "Giving Consent",
            columnImage: "assets/img/au_TheConsentCrashCourse/VectorSmartObject.png",
            columnData: [
                "No one has the right to pressure you if you do not feel comfortable engaging in any type of activity. Sexual coercion is a common avenue to sexual assault. This is when someone pressures, threatens or tricks another in a non-verbal way into a sexual act.",
                "Always be clear about your intentions and know that your boundaries are yours to outline. ",
                "Try to be vocal when you like something your partner is doing! "
            ]
        },
        {
            columnClass: "column-6",
            columnHeading: "Withdrawing Consent",
            columnImage: "assets/img/au_TheConsentCrashCourse/VectorSmartObject2.png",
            columnData: [
                "If someone withdraws consent from you, even during intercourse, you must respect this. You can withdraw consent at any point if you feel uncomfortable! You can do so by communicating to your partner that you’re no longer comfortable with an activity and you wish to stop. Some ways to withdraw consent include: “I’m not enjoying this anymore” “I don’t like that”, “that doesn’t feel good”, “can we stop and chill out for a bit?” or “I changed my mind, I don't want to do this”.",
                "Withdrawing consent can sometimes be challenging or difficult to do so verbally, so non-verbal cues can also be used to convey this. If someone becomes hesitant or tenses up, you should take that as a cue that they are withdrawing consent and would like to stop. Try saying ‘do you want to just cuddle for a bit?’ ",
                "Body language that indicates discomfort, fear or disengagement are also signs the no consent has been provided. Silence, turning away, moving away, averting eyes are all examples of not giving consent.",
                "The best way to ensure all parties are comfortable with any sexual activity is to talk and check in periodically, making sure everyone involved consents before escalating or changing activities.  Remember unless it is enthusiastic, both verbally AND physically, then it’s not consent.",

            ]
        },
    ],
    endPara: [
        "Do you have a better understanding of consent? Remember, it’s not just the absence of a ‘NO’ that counts as consent, it’s the (enthusiastic) presence of a ‘YES!’. Repeat after us: When in doubt, just ask and ‘yes’ only counts if ‘no’ is safe to say! 👏 ",
        "If anyone violates your consent consider reporting it to the police and Tinder. You can report anyone on Tinder regardless for both their online or offline behaviour and can select from a number of reasons for reporting such as harassment/threatening behaviour or physical safety concerns. The details will not be shared with the person you’re reporting. Because of privacy guidelines, we may not always be able to share the details of a report with you, but every report is taken seriously and handled with care. Sharing your experience through reporting isn't always easy, and we appreciate it when you do."
    ]
}




export type { data }
export { SafetySeminar, ConsentCrashCourse }