import { IonContent, IonPage, IonImg, useIonViewDidLeave } from '@ionic/react';
import { useContext, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { PdfContext } from '../components/PdfViewer';
import { LanguageContext } from '../components/LanguageSelect';

const SafetySyllabus: React.FC = () => {
  const { language } = useContext(LanguageContext);
  const { setModalVisible, setDesktopPdf, setMobilePdf } = useContext(PdfContext);
  const [swiper, setSwiper] = useState<SwiperCore>();
  // set the swiper slide to the first one when the component is mounted
  useIonViewDidLeave(() => {
    swiper?.slideTo(0);
  }, [swiper]);
  return (
    <>
      <IonPage>
        <IonContent fullscreen>
          <Swiper
            initialSlide={0}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            onSwiper={(swiper) => setSwiper(swiper)}
            slidesPerView={1}
            // loop={true}
            navigation
            pagination={{ clickable: true }}
            spaceBetween={0}
            centeredSlides={true}
            breakpoints={{
              768: {
                slidesPerView: 1.2,
                spaceBetween: -20
              },
              1024: {
                slidesPerView: 1.4,
                spaceBetween: -120
              },
            }}
          >
            <SwiperSlide>
              <div className="container-inner">
                <div className="d-flex">
                  <h1 className='container-title' style={{ background: '#ff00c2', color: '#fff' }}>{
                    language === 'id' ? 'Silabus Keamanan' :
                      language === 'vn' ? 'Hội thảo về An toàn' :
                        language === 'th' ? 'หลักสูตรความปลอดภัย' :
                          `Safety Syllabus`}</h1>
                  <div className='container-content' style={{ background: '#fff', color: '#303030', paddingTop: '0.1rem' }}>
                    <p>
                      {
                        language === 'id' ? `Bertemu dengan orang baru pasti menyenangkan selama kamu mengutamakan keamanan!\n\nSimak petunjuk lengkap ini untuk menikmati pengalaman online dating dengan berhati-hati, baik secara online atau offline. Pembahasannya lengkap, mulai dari Kesadaran, Batasan & Consent, dan berbagai hal lainnya.` :
                          language === 'vn' ? 'Bắt sóng tần số với người mới quen luôn là điều khá hào hứng nhưng nó chỉ thực sự thú vị chỉ khi bạn đặt sự an toàn của bản thân lên hàng đầu! Cùng khám phá toàn bộ hướng dẫn bên dưới về cách tận dụng tối đa trải nghiệm tương hợp mà vẫn đảm bảo được sự an toàn của bạn khi trực tuyến lẫn ngoài đời. Chúng tôi đề cập đến vấn đề an toàn về Nhận thức, Ranh giới và Sự Đồng thuận cùng vô số những vấn đề khác.' :
                            language === 'th' ? `การรู้สึกดีกับคนใหม่เป็นเรื่องน่าตื่นเต้นอยู่แล้ว แต่จะดีกว่าถ้าคุณให้ความสำคัญกับความปลอดภัยเป็นอันดับแรก\n\nลองดูคำแนะนำที่เรารวมทุกเรื่องเกี่ยวกับวิธีปฏิบัติอย่างไร ให้ได้รับประสบการณ์ที่ดีในการจับคู่ Match มากที่สุด และสามารถระวังเรื่องความปลอดภัยของตัวเองได้ทั้งบนโลกออนไลน์และออฟไลน์ ในบทเรียนนี้เราจะพูดถึงความปลอดภัยแบบครอบคลุมในเรื่อง ก. ข. ค. (การตระหนักรู้  ขอบเขต ความยินยอม) และอีกหลายประเด็น` :
                              `Vibing with new people is always exciting but only when you keep your safety first! \n\nCheck out this all-in-one guide on how to make the most of your matching experience while also staying careful, both online and offline. We cover the ABC’s of safety (Awareness, Boundaries & Consent!) along with so much more.`}
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="container-inner design-one">
                <div className="d-flex">
                  <h1 className='container-title' style={{ background: '#fd5466', color: '#fff' }}>{
                    language === 'id' ? `Pusat Keamanan` :
                      language === 'vn' ? 'Chuyên đề về An toàn' :
                        language === 'th' ? 'สัมมนาเรื่องความปลอดภัย' :
                          `Safety Seminar`}</h1>
                  <div className='container-content' style={{ background: '#fff', color: '#303030' }}>
                    <p className='hide-desktop'>{
                      language === 'id' ? `Keamanan yang utama` :
                        language === 'vn' ? 'Không phải con gà hay quả trứng, mà sự an toàn của bạn phải được đặt lên đầu tiên!' :
                          language === 'th' ? 'ไม่สำคัญว่าไก่หรือไข่จะมาก่อนกัน เพราะความปลอดภัยของคุณมาก่อนเสมอ' :
                            `Neither the chicken nor the egg, your safety comes first!`}</p>
                    <IonImg className="hide-mobile" src={
                      language === 'id' ? `assets/img/id-safety-seminar-thumb-01.jpg` :
                        language === 'vn' ? `assets/img/vn-safety-seminar-thumb-01.jpg` :
                          language === 'th' ? `assets/img/th-safety-seminar-thumb-01.jpg` :
                            `assets/img/safety-seminar-thumb-01.jpg`}></IonImg>
                    <IonImg className="hide-desktop" src={
                      language === 'id' ? `assets/img/id-safety-seminar-thumb-01-mobile.jpg` :
                        language === 'vn' ? `assets/img/vn-safety-seminar-thumb-01-mobile.jpg` :
                          language === 'th' ? `assets/img/th-safety-seminar-thumb-01-mobile.jpg` :
                            `assets/img/safety-seminar-thumb-01-mobile.jpg`}></IonImg>
                    <div className='flex'>
                      <p className='hide-mobile' style={{ maxWidth: '550px' }}>{
                        language === 'id' ? 'Keamanan yang utama' :
                          language === 'vn' ? 'Không phải con gà hay quả trứng, mà sự an toàn của bạn phải được đặt lên đầu tiên!' :
                            language === 'th' ? 'ไม่สำคัญว่าไก่หรือไข่จะมาก่อนกัน เพราะความปลอดภัยของคุณมาก่อนเสมอ' :
                              `Neither the chicken nor the egg, your safety comes first!`}</p>
                      <button onClick={() => {
                        setDesktopPdf(
                          language === 'id' ? `/assets/pdf/ID_Safety.pdf` :
                            language === 'vn' ? `/assets/pdf/VN_Safety.pdf` :
                              language === 'th' ? `/assets/pdf/TH_Safety.pdf` :
                                `/assets/pdf/Safety.pdf`);
                        setMobilePdf(
                          language === 'id' ? `/assets/pdf/ID_Safety(m).pdf` :
                            language === 'vn' ? `/assets/pdf/VN_Safety(m).pdf` :
                              language === 'th' ? `/assets/pdf/TH_Safety(m).pdf` :
                                `/assets/pdf/Safety(m).pdf`
                        );
                        setModalVisible(true);
                      }} style={{ color: "#fff", background: "#fd5466" }}>{
                          language === 'id' ? 'Stay Safe!' :
                            language === 'vn' ? 'Giữ an toàn' :
                              language === 'th' ? 'ปลอดภัยไว้ก่อน' :
                                `Stay Safe`}</button>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="container-inner design-one">
                <div className="d-flex">
                  <h1 className='container-title' style={{ background: '#186ac4', color: '#fff' }}>{
                    language === 'id' ? 'Belajar Tentang Consent' :
                      language === 'vn' ? 'Khóa học cơ bản về sự đồng thuận' :
                        language === 'th' ? 'คอร์สเรียนเรื่องความยินยอมฉบับเร่งด่วน' :
                          `Consent Crash Course`}</h1>
                  <div className='container-content' style={{ background: '#fff', color: '#303030' }}>
                    <p className='hide-desktop'>{
                      language === 'id' ? 'Panduan tentang cara menetapkan dan menyampaikan batasan kamu dengan jelas!' :
                        language === 'vn' ? 'Hướng dẫn về cách đánh dấu ranh giới và giao tiếp rõ ràng!' :
                          language === 'th' ? 'คำแนะนำในการหาทางว่า ทำอย่างไรถึงจะมีขอบเขตที่พอดี และสามารถสื่อสารกันได้อย่างชัดเจน' :
                            `A guide on how to navigate boundaries and communicate clearly!`}</p>
                    <IonImg src='assets/img/safety-consent-thumb-01.png'></IonImg>
                    <div className='flex'>
                      <p className='hide-mobile' style={{ maxWidth: '550px' }}>{
                        language === 'id' ? 'Panduan tentang cara menetapkan dan menyampaikan batasan kamu dengan jelas!' :
                          language === 'vn' ? 'Hướng dẫn về cách đánh dấu ranh giới và giao tiếp rõ ràng!' :
                            language === 'th' ? 'คำแนะนำในการหาทางว่า ทำอย่างไรถึงจะมีขอบเขตที่พอดี และสามารถสื่อสารกันได้อย่างชัดเจน' :
                              `A guide on how to navigate boundaries and communicate clearly!`}</p>
                      <button onClick={() => {
                        setDesktopPdf(`/assets/pdf/Consent.pdf`);
                        setMobilePdf(
                          language === 'id' ? `/assets/pdf/ID_Consent(m).pdf` :
                            language === 'vn' ? `/assets/pdf/VN_Consent(m).pdf` :
                              language === 'th' ? `/assets/pdf/TH_Consent(m).pdf` :
                                `/assets/pdf/Consent(m).pdf`);
                        setModalVisible(true);
                      }} style={{ color: "#fff", background: "#186ac4" }}>{
                          language === 'id' ? 'Dapatkan consent!' :
                            language === 'vn' ? 'Hiểu về Đồng thuận' :
                              language === 'th' ? 'การยินยอมที่คุณเลือกได้' :
                                `Get Consent`}</button>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </IonContent>
      </IonPage>
    </>
  );
};

export default SafetySyllabus;
