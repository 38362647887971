import { IonImg } from '@ionic/react';
interface data {
    isActive?: boolean,
    accordianTitle: string,
    accordianDesc: string,
    longTitle: string,
    longDesc: string[],
    columns: {
        columnClass: string,
        columnHeading: string,
        columnImage: string,
        columnData: string[]
    }[],
    endPara: string[]
    HtmlEntity?: JSX.Element,

}

const HtmlEntityData = () => {
    return <div>
        <h3 className="flex-in-our-features-center-heading transparent-heading">Pictures</h3>
        <div className="columns">
            <div className="column-default column-6">
                <div className="column-img opacity-05"><IonImg src="assets/img/au_profile101/VectorSmartObject.png" /></div>
                <h2>Make it clear who you are</h2>
                <div>
                    <p>One thing is for certain - a picture tells a thousand words, and we also know that daters value authenticity.</p>
                    <p>Make sure to include a couple of clear, in-focus pictures of you – with a good view of your face! No matter how cool you think your Pit Vipers are, hiding your face behind sunglasses is not going to help people figure out what you look like.</p>
                    <p>Of course, a pic of you with your pet, next to your car or beside a waterfall is good and shows off the various elements of our personality. Just make sure that these aren’t the core focus of your whole profile.</p>
                    <p>If you have a bunch of cool tatts that you want to show off, go all the way! Choose a photo that shows them off but forget zoomed in, out-of-context tattoo pics.</p>
                </div>
            </div>
            <div className="column-default column-1">
                <div className="column-img"></div>
                <h2>Minimise the amount of group shots</h2>
                <p>Most people on a dating app won’t want to use a process of elimination to figure out who you are in a profile full of group pics. Show off your friends and family by all means, but keep it to just one picture among a selection of other pictures of you so people really know who they’re matching with.</p>
            </div>

            <div className="column-default column-6">
                <div className="column-img"><IonImg src="assets/img/au_profile101/VectorSmartObject2.png" /></div>
                <h2>Less (selfies) is more</h2>
                <div>
                    <p>The common bathroom selfie not only often looks lazy, but if you’ve taken it in a mirror that you’ve forgotten to clean you can just about kiss that match goodbye. Using one selfie among a good mix of other photos of you is more than fine, but a profile composed of selfies alone is not a good look.</p>
                    <p>If you feel awkward asking friends to take that perfect photo for you, you can always set up your phone with a tripod and use the timer function.</p>
                </div>
            </div>

            <div className="column-default column-6">
                <div className="column-img"></div>
                <h2>Honesty is the best policy</h2>
                <div>
                    <p>Using a range of pictures that show off your current look will save you a lot of trouble later. If you once had flowing locks, but now have a shaved head or mullet, include pics showing your new style.</p>
                    <p>While you might have looked fantastic in pictures from your high school formal, they probably won’t represent what you look like a couple of years later.</p>
                    <p>People meeting you will feel misled if you arrive looking noticeably different from the pictures on your profile - not because they’re shallow, but because you weren’t honestly showing yourself off.</p>
                    <p>No matter how great your personality, how cute your dog or how much money you have, your date is unlikely to forgive deliberate dishonesty.</p>
                </div>
            </div>
        </div>
        <h3 className="flex-in-our-features-center-headin transparent-heading">Bio</h3>

        <div className='columns'>
            <div className='column-default column-1 flex-columns-saftySyllabus'>
                <div>
                    <IonImg className='column-img opacity-05' src="assets/img/au_profile101/VectorSmartObject4.png"></IonImg>
                </div>
                <div>
                    <h2>Provide some substance</h2>
                    <div>
                        <p>Vague statements about liking to ‘relax and unwind’ or your love of ‘random road trips’ might seem like they capture your personality but they don’t mean much to people reading your profile. In the same way, there’s no faster way to receive an eye roll than writing ‘just ask’ in your bio.</p>
                        <p>The best way to inject some enthusiasm into your bio is to use active language. Instead of saying you’re ‘adventurous’, write about the adventures you’ve been on. Similarly, you’ll sound more interesting if you mention some of the new or crazy things you’ve tried instead of just saying ‘I love trying new things.’</p>
                        <p>
                            Some examples of great active language are: <br />
                            ‘I love rock climbing and mountain biking most weekends or reading ancient Greek literature after a big day.’<br />
                            ‘Ask me about the time I went yak racing in Mongolia.’
                        </p>
                        <p>
                            Including a thoughtful/funny question in your bio is also a great way to offer a potential match, an intuitive way to break the ice with a first message, giving you the best opportunity to encourage more conversations.<br />
                            Eg: ‘Would you prefer to have $14m now or go back and live as your 14-year-old self with all your future knowledge?’
                        </p>
                    </div>
                </div>
            </div>


            <div className='column-default column-1 flex-columns-saftySyllabus flex-columns-bio-is-not-therapy padding'>
                <div>
                    <h2>Bios ≠ Therapy</h2>
                    <div className='max-width-80percent'>
                        <p>
                            Writing about how hard dating is or how much easier others have it doesn’t provide a great first impression of your attitude and might net you an instant nope.<br />
                            We get it, dating can be difficult, but complaining won’t help you connect with kindred spirits who feel the same.
                        </p>
                        <p>
                            It’s only going to make people wonder if the problem might, in fact, be you. Think about it this way - if you tell the world that others are staying away from you, what is attracting a potential match to stick around?
                        </p>
                    </div>
                </div>
                <div>
                    <IonImg src="assets/img/au_profile101/VectorSmartObject3.png" className='emojiImgs'></IonImg>
                </div>
                <div className='column-default column-1'>
                    <div className='text-center padding'>
                        <h2>Avoid creating a laundry list</h2>
                        <p>
                            Whether it’s things you want or don’t want, listing criteria for a match in your bio can make you come across as a bit jaded.<br />
                            You might think you’re being upfront but a list of demands or turn offs will likely leave a sour taste in people’s mouths.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const HtmlEntityDatingTextingData = () => {
    return <div>
        <h3 className="flex-in-our-features-center-heading transparent-heading">Messaging 101</h3>
        <div className="columns">
            <div className="column-default column-1 padding">
                <div className="column-img opacity-05"><IonImg src="assets/img/au_dating101/VectorSmartObject.png" /></div>
                <h2>Opening</h2>
                <div>
                    <p>Opening with just a ‘Hi’ or ‘Hey’ will probably get you ignored - it’s about as low-effort of a message as you can get and you’ll need to do more to increase your chances of a response.</p>

                    <p>
                        Try asking your match about something in their profile that caught your eye, or responding to a part of their bio to show that you are interested in them.<br />
                        Eg: ‘Hey Mel, did you hike to Machu Picchu or did you take the bus?’
                    </p>
                    <p>
                        If your match’s profile is a bit empty, it's ok to ask about their day! Don’t be afraid to use their name to make it more personal and sound more exciting.<br />
                        Eg: ‘What's happening with John on this amazing Tuesday?’
                    </p>
                </div>
            </div>
            <div className="column-default column-1">
                <div className="column-img"></div>
                <h2 className='text-center'>Let the conversation flow</h2>
                <p className='text-center'>
                    Letting a conversation flow is more simple than it sounds. Be present. Respond to what your match says. Ask questions. Share your own thoughts and experiences.<br />
                    Feel free to move the chat to a different topic once you feel something has run its course.<br />
                    Try to respond to your match as soon as you can, waiting a couple of days often means they’ll lose interest in the convo, or think they’ve been ghosted.<br />
                </p>
            </div>

            <div className="column-default column-1">
                <div className="column-img"><IonImg src="assets/img/au_dating101/VectorSmartObject2.png" /></div>
                <h2 className='text-align-right'>What to do when your high energy messages receive low-effort responses</h2>
                <div>
                    <p>
                        If you find yourself carrying the conversation, your message bubbles are bigger than your match, or they never seem to ask questions back – you’re getting low-effort responses.
                    </p>
                    <p>
                        It might be that they have a lot of matches or maybe they’re just not a good texter. Either way, the reasons don’t change the reality.
                    </p>
                    <p>
                        If the low effort responses bother you, it can be easiest to simply stop messaging your match. There’s little joy in spending that wasted energy just to receive lazy responses. Make sure you let them know that you don’t want to pursue this match - nobody likes a ghost.
                    </p>
                    <p>
                        If you do want to keep it going, you can always drop your effort and engagement to match theirs. Just look at their communication style and respond in a similar way.
                    </p>
                    <p>
                        If it’s been dragging on for a while, you could also ask to meet in person to quickly conclude things. They might just stop responding or even agree to meet up. EIther way, you won’t be stuck in a grey-zone.
                    </p>
                </div>
            </div>
        </div>
        <h3 className="flex-in-our-features-center-heading transparent-heading">Dating 101</h3>

        <div className='columns'>
            <div className='column-default column-6'>
                <IonImg className='column-img opacity-05' src="assets/img/au_dating101/VectorSmartObject3.png"></IonImg>
                <h2>Ask to meet in person</h2>
                <div>
                    <p>Even if your match has an amazing text game and is great on a video call, you never know for sure what the vibe is until you meet in person.</p>
                    <p>Test the waters with a simple ‘Would you like to meet for a coffee next week?’ If they respond well, pitch a location and a time to meet. Even if it’s not going out for a coffee, it helps to have an idea of what you want to do when you meet up with your match. Whether it’s dinner and drinks or just a walk in the park, sharing some date ideas beyond asking to ‘hangout sometime’ shows that you are putting in effort.</p>
                </div>

            </div>


            <div className="column-default column-1">
                <div className="column-img"><IonImg src="assets/img/au_dating101/VectorSmartObject8.png" /></div>
                <h2>Dress for success</h2>
                <div>
                    <p>No one expects you to show up in a tuxedo or ball gown for a coffee date but you've done the hard work of securing an IRL date and you want it to be a great experience for both of you. It would be a shame to ruin the experience because of something preventable.</p>
                    <p>Match your outfit to the type of date that you’re going on. An activewear fit might be perfect for an outdoorsy hike or rock climbing date, but you’d want something a bit dressier if you’re meeting someone out for drinks. Pottery date? Think about jeans and a top you don’t mind getting a bit of clay on. Picnic date? Flex that cottagecore wardrobe. </p>
                </div>
            </div>


            <div className='column-default flex-columns-saftySyllabus flex-columns-bio-is-not-therapy padding'>
                <div className='column-img'></div>
                <div className='text-center padding'>
                    <h2>Dates aren’t therapy</h2>
                    <p>
                        Don’t spill your whole life drama on the first date. It doesn’t matter how toxic your ex was or how frustrating dating can be, you’re not at therapy.<br /><br />
                        The early days of dating are to see if you like each other and hopefully enjoy a fun vibe. There’s a time and place to vent about exes. A first date with a person you just met on Tinder isn’t it.
                    </p>
                </div>
            </div>

            <div className='column-default column-6'>
                <IonImg className='column-img' src="assets/img/au_dating101/VectorSmartObject4.png"></IonImg>
                <h2>Ask questions and let them talk</h2>
                <div>
                    <p>Talking about yourself the whole time is never the best idea.It’s great to speak passionately about something you love, but you’ll look a lot better if you also ask questions and let your date talk about their own interests too.</p>

                    <p>Ask open-ended questions to keep the conversation ticking over. Good examples of open ended questions include: ‘Tell me more about the paintings you do?’ or ‘How does meditation work for you?’</p>

                    <p>
                        When someone’s telling you about something they love, and they can see you listening and engaging, they’re bound to feel special.<br />
                        And remember, an IRL date isn’t just about seeing if your date likes you – it’s also about you deciding if you like them!
                    </p>
                </div>
            </div>
        </div>
        <div className="columns">
            <h3 className="flex-in-our-features-center-heading transparent-heading">Managing your investment in your data</h3>
            <div className='column-default column-6 padding'>
                <IonImg src="assets/img/au_dating101/VectorSmartObject5.png" className='emojiImgs'></IonImg>
                <div>
                    <h2>Don’t overcommit early</h2>
                    <div>
                        <p>A walk in the park, coffee or drink is a quick and easy way to see if there’s a connection. You’re not being cheap. It’s about valuing your time and not overinvesting in someone you don’t know well yet.</p>
                        <p>Three hours and $200 for dinner is a huge price to discover there’s no vibe, so save the extravagant dates or long, adventurous activities for later in the relationship.</p>
                    </div>
                </div>
            </div>
            <div className='column-default column-1 flex-columns-saftySyllabus padding'>
                <div>
                    <h2>Decline the demands</h2>
                    <div>
                        <p>If someone only agrees to meet provided you pay for an expensive dinner, then it’s quite likely  they aren’t interested in you – they’re only interested in a free, five-star meal. </p>
                        <p>Likewise, if someone asks you to send them money or gifts, they’re probably not on Tinder to have fun dates or meet someone special. </p>
                    </div>
                </div>
                <div>
                    <IonImg src="assets/img/au_dating101/VectorSmartObject6.png" className='emojiImgs'></IonImg>
                </div>
            </div>
            <p className='padding'>
                A great connection or meetup shouldn’t cost you an arm and a leg. If you’re not sure whether someone is dating with the right intentions, check out this Romance Scam Factsheet to help protect yourself online, and always remember to report inappropriate behaviour.
            </p>
            <div className='column-default column-1 padding'>
                <IonImg className='column-img' src="assets/img/au_dating101/VectorSmartObject7.png"></IonImg>
                <div>
                    <h2>Stay in the present</h2>
                    <div>
                        <p>Your match may have the most infectious smile or enchanting eyes, a bio that made you laugh out loud and the cutest pet you’ve ever seen. But you’re still in the early stages of dating.</p>
                        <p>You’re allowed to be excited that they liked you back, but making plans for a European holiday or fantasising about what your future kids would look like is precisely what over-investment is and can sometimes lead to disappointment.</p>
                        <p>Keep putting in the effort, but keep your own expectations realistic and conserve energy to avoid any potential disappointment.</p>
                    </div>
                </div>
            </div>
            <div className='column-default flex-columns-saftySyllabus flex-columns-bio-is-not-therapy padding'>
                <div className='column-img'>
                </div>
                <div className='text-center padding'>
                    <h2>Start as you’ll continue</h2>
                    <p>
                        Making promises you won’t keep or pretending to be something you’re not will just lead to drama, fights and disappointment.<br /><br />
                        Things might be fine at first, but not when they discover you don’t actually like cooking, romantic comedies or high tea with a partner's friends.<br /><br />
                        You’ll look shonky at best, or a complete fake who lies their way into someone's pants at worst. Be honest from the outset and be authentic to your values.
                    </p>
                </div>
            </div>


        </div>
    </div>
}

const HtmlEntityHandlineRejectionData = () => {
    return (
        <div>
            <h3 className="flex-in-our-features-center-heading transparent-heading">Politely rejecting someone</h3>
            <div className="columns">
                <div className="column-default column-1 padding">
                    <div className='column-img'>
                        <IonImg src="assets/img/au_HandlingRejection/VectorSmartObject5.png"></IonImg>
                    </div>
                    <div>
                        <h2>Be Clear</h2>
                        <div>
                            <p>Lead  with the fact that things aren't continuing.</p>
                            <p>‘Hi Dave. It was nice to meet you but I don't see things going any further, but I wish you all the best.’</p>
                            <p>‘Hi Jen, thanks for meeting up. I just don't feel a spark but I'm sure you'll find someone soon.’</p>
                        </div>
                    </div>
                </div>
                <div className="column-default column-6 padding">
                    <div className='column-img'>
                        <IonImg src="assets/img/au_HandlingRejection/VectorSmartObject4.png"></IonImg>
                    </div>
                    <div>
                        <h2>Stand Firm</h2>
                        <div>
                            <p>Giving someone the reasons as to why you don’t want to continue seeing things out may lead to arguments and prolong the unfortunate circumstances.</p>
                            <p>If they get angry or demand an explanation over messages – stop responding. If it’s in person, repeat what you said once then leave. It’s the same if they pretend to not understand or act like you weren't clear.</p>
                            <p>Rejected people may think that continuing a conversation is positive, because you’re still interacting with them and feel like they have a ‘chance’ to salvage the situation.</p>
                        </div>
                    </div>
                </div>
                <div className='padding'>
                    <p>They may not consider the outcome to be ‘fair’, but it's not your job to help them process their emotions.</p>
                    <p>Don't be afraid to block anyone who persists in trying to engage with you after you’ve politely rejected them and make sure to report them on Tinder if they become abusive.</p>
                </div>

                <div className="column-default column-1 padding">
                    <div className='column-img'>
                        <IonImg src="assets/img/au_HandlingRejection/VectorSmartObject3.png"></IonImg>
                    </div>
                    <div>
                        <h2>Finding closure from rejection</h2>
                        <div>
                            <p>Does your match only respond with vague messages? Have they cancelled a date? Were you ghosted? Chances are they've taken the lazy way out in rejecting you.</p>
                            <p>Maybe they aren’t comfortable with the arrangement anymore, have reconsidered their dating priorities or are simply seeing someone else.  Perhaps they just didn’t feel the vibe. Whatever the reason, you can’t change the fact that you’ve been rejected and there’s probably no explanation you're going to like.</p>
                        </div>
                    </div>
                </div>

                <div className="column-default column-6 padding">
                    <div className='column-img'>
                        <IonImg src="assets/img/au_HandlingRejection/VectorSmartObject.png"></IonImg>
                    </div>
                    <div>
                        <h2>‘Politeness’ doesn’t change things</h2>
                        <div>
                            <p>People often feel that sending vague messages, ghosting, or cancelling meetups are a ‘nice’ way to let someone down easily, but it’s important to recognise that this is still a form of rejection as much as an outright verbal rejection.</p>
                            <p>No matter how polite, impolite or unfair you think a rejection is, it’s important to understand that things have changed and it’s time to move on.</p>
                        </div>
                    </div>
                </div>

                <div className="column-default column-1 padding">
                    <div className='column-img'>
                        <IonImg src="assets/img/au_HandlingRejection/VectorSmartObject2.png"></IonImg>
                    </div>
                    <div>
                        <h2>Outcome Independence</h2>
                        <div>
                            <p>It's ok to be excited about meeting someone new - in fact you probably should be excited - but that shouldn’t be something that determines your happiness. Happiness shouldn’t depend on whether someone messages you back or goes on a date with you.</p>
                            <p>Work to make your happiness independent of what happens on a dating app. A full and exciting life outside of the world of dating will make you more attractive in the long run.</p>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

const HtmlEntityHandlineToxicBehavioursData = () => {
    return (
        <div>
            <h3 className="flex-in-our-features-center-heading transparent-heading">What to do if things turn toxic</h3>
            <div className="columns">
                <div className="column-default column-1 padding">
                    <div className='column-img'>
                        <IonImg src="assets/img/au_HandlingToxicBehaviours/VectorSmartObject3.png"></IonImg>
                    </div>
                    <div>
                        <h2>Categorise your experience</h2>
                        <div>
                            <p>Sometimes it can be hard to know when someone’s behaviour towards you is becoming toxic, especially when you’re emotionally invested in your connection to them. If you find yourself being exhausted by every interaction, this is a good sign the connection is starting to weaken.</p>
                            <p>Perhaps you’ve caught someone in a lie one-too-many times, or they’re continuing to put you down even after you’ve shown you’re not into that type of ‘banter’. Making a list of these experiences can help you to visualise what you’re experiencing.</p>
                        </div>
                    </div>
                </div>
                <div className="column-default column-6 padding">
                    <div className='column-img'>
                        <IonImg src="assets/img/au_HandlingToxicBehaviours/VectorSmartObject2.png"></IonImg>
                    </div>
                    <div>
                        <h2>Put yourself first</h2>
                        <div>
                            <p>As much as you want to be finding new connections on Tinder, you’ve got to look after number one – yourself. Although you might find yourself tempted to ‘fix’ someone who continues to demonstrate toxic behaviour, don’t offer support at the expense of your own wellbeing.</p>
                            <p>It is definitely worth discussing the behaviour with them if you have concerns – the mark of a good relationship (romantic or otherwise) is someone who is willing to identify and learn from their mistakes.</p>
                            <p>If you still find nothing changes, it’s time to set some firmer boundaries.</p>
                        </div>
                    </div>
                </div>
                <div className="column-default column-1">
                    <div className='column-img'>
                        <IonImg src="assets/img/au_HandlingToxicBehaviours/VectorSmartObjectcopy.png"></IonImg>
                    </div>
                    <div className='text-center'>
                        <h2>Boundary setting</h2>
                        <div>
                            <p>The best thing to do when experiencing toxic behaviour is to walk away. Let’s face it, the toxicity can’t affect you if you’re not there to cop it. We know it’s not always that easy but we’ve got to say it.</p>
                            <p>If you do find yourself needing to maintain some kind of connection, maybe your parents are friends or you’ve already met each other's mates, it can help to establish firm boundaries. Communicate your boundaries to the person you’re interacting with and stick to them. Have some kind of exit strategy in place if they continue to cross your boundaries, whether that’s taking a time out from messaging or hanging out, or just flat out blocking them.</p>
                        </div>
                    </div>
                    <div className='column-img'>
                        <IonImg src="assets/img/au_HandlingToxicBehaviours/VectorSmartObject4.png"></IonImg>
                    </div>
                </div>
                <div className="column-default column-6 padding">
                    <div className='column-img'>
                        <IonImg src="assets/img/au_HandlingToxicBehaviours/VectorSmartObject.png"></IonImg>
                    </div>
                    <div>
                        <h2>Embrace in-app features</h2>
                        <div>
                            <p>If you experience any kind of toxic behaviour on Tinder, you can take action and help ensure their actions have relative consequences.</p>
                            <p>When you’re dealing with ‘low-level’ icks or creeps, maybe someone who doesn’t have the same values as you, the easiest thing to do is to unmatch and block them. This will take them away from your Tinder feed forever, making sure you don’t have to deal with that uncomfortable situation again.</p>
                            <p>If they go into abusive and creepy territory however, reporting them will ensure the Tinder team can easily review the interaction and take more decisive action including blocking their account from the app. Tinder will even flag potentially offensive messages with a pop-up so you can quickly report or block matches in-app.</p>
                        </div>
                    </div>
                </div>
                <div className="column-default column-1">
                    <div className='column-img'>
                        <IonImg src="assets/img/au_HandlingToxicBehaviours/VectorSmartObject5.png"></IonImg>
                    </div>
                    <div>
                        <h2>Staying safe outside the app</h2>
                        <div>
                            <p>While Tinder has a large complement of safety features, it’s inevitable you will eventually take the interaction off the app, whether that’s meeting in person or exchanging numbers.</p>
                            <p>When you do meet up with a match for the first time, it’s best to meet in a public place so there are other people around who you could go to for help. This also helps to keep some of your privacy intact. You might also want to screenshot any messages you’ve received in the leadup to meeting with someone if you feel something is off.</p>
                            <p>If your date does anything that crosses serious boundaries or is harmful to yourself or others, then please report them to the police, as well as to Tinder. Above all, it’s important to trust your instincts - if you feel something is not right, don’t take the chance on finding out.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const HtmlEntityTopFiveMistakesData = () => {
    return (
        <div>
            <div className="columns">
                <div className="column-default column-6 padding">
                    <div className='column-img'>
                        <IonImg src="assets/img/au_topFiveMistakes/1.png"></IonImg>
                    </div>
                    <div>
                        <h2>Taking too long to meet IRL</h2>
                        <div>
                            <p>You might be in love with someone’s profile and their text game may be on point, but you'll never know if it's meant to be until you meet in person.</p>
                            <p>The way someone comes across over text, isn’t always the same as they are IRL.</p>
                            <p>It’s going to be disheartening if you message back and forth for months, only to eventually meet and discover in five minutes that they’re not for you.</p>
                        </div>
                    </div>
                </div>
                <div className="column-default column-6 padding">
                    <div className='column-img'>
                        <IonImg src="assets/img/au_topFiveMistakes/2.png"></IonImg>
                    </div>
                    <div>
                        <h2>Being too unavailable</h2>
                        <div>
                            <p>If you're on Tinder but won't be able to talk or meet anyone for the next eight weeks, it might be worth coming back when you have more free time.</p>
                            <p>It’s unlikely you’re going to hold their interest if you can’t interact for a while. They’re most likely  going to have interest from other people between now and then, so if you aren’t free within a reasonable time, someone else will be.</p>
                        </div>
                    </div>
                </div>

                <div className="column-default column-6 padding">
                    <div className='column-img'>
                        <IonImg src="assets/img/au_topFiveMistakes/3.png"></IonImg>
                    </div>
                    <div>
                        <h2>Getting too intimate too quickly</h2>
                        <div>
                            <p>If someone’s just finished telling you about their European holiday, it’s not a good time to mention your kinks.  Likewise, if you’re on a first date eating hotdogs, it’s best to save the sausage­-and-bun jokes for another outing.</p>
                            <p>It’s ok to talk about sex or make sexual jokes, but pick the right time, place and person.</p>
                            <p>Bringing up sex at the wrong moment can put off a person who was otherwise into you, especially if it’s someone you’ve only just started talking to.</p>
                        </div>
                    </div>
                </div>

                <div className="column-default column-6 padding">
                    <div className='column-img'>
                        <IonImg src="assets/img/au_topFiveMistakes/4.png"></IonImg>
                    </div>
                    <div>
                        <h2>Thinking they owe you something</h2>
                        <div>
                            <p>The important thing to understand when dating is that people you match with have no obligation to you. </p>
                            <p>Some matches may respond immediately and be great conversationalists, others may just never message you at all. You might even find that someone you finally meet in person loses interest and gradually stops responding.</p>
                            <p>While it can be frustrating to deal with these situations, remember that someone’s lack of effort may have nothing to do with you. It’s important to recognise that people have their own reasons for not wanting to pursue anything. Nobody is owed a second date or a return message – and that goes for people expecting this from you too!</p>

                        </div>
                    </div>
                </div>

                <div className="column-default column-6 padding">
                    <div className='column-img'>
                        <IonImg src="assets/img/au_topFiveMistakes/5.png"></IonImg>
                    </div>
                    <div>
                        <h2>Obsessing about dating</h2>
                        <div>
                            <p>Dating should be fun and meeting new people can be exhilarating. But, as exciting as it can be, you shouldn’t view Tinder and online dating the same as your other hobbies, whether that’s going to the footy or seeing a movie.</p>
                            <p>No one on the app owes you entertainment, let alone a conversation or meetup. It can feel like a fun game but it’s important to remember that you, just like everyone else on Tinder, have a whole life outside of the app.</p>
                        </div>
                    </div>
                </div>




            </div>
        </div>
    )
}
const Profile101: data = {
    accordianTitle: "Profile 101",
    accordianDesc: "",
    longTitle: "Profile 101",
    longDesc: [
        "If you’re not outwardly confident or trying to go for a mysterious vibe, you might opt for vague photos, snaps of a sunset and a couple of emojis in your Tinder profile.",
        "The reality is, if you’re on Tinder to find someone you should bring your A-game. You wouldn’t only do half a warm up for your footy match or learn half a song before karaoke night, so why wouldn’t you give yourself the best chance possible?",
        "The more effort you put into constructing your profile, the more you’re going to show a potential match that you’re the real deal and genuinely looking for connection.",
        "A good variety of pictures and an interesting bio can prompt a quick Swipe RightTM or even just convince a curious potential date to take a closer look at your profile before tapping that green heart.",
        "Here are a couple of tips to help you put your best foot forward and make that profile work for you."
    ],
    columns: [],
    HtmlEntity: <HtmlEntityData />,
    endPara: []

}

const datingTexting101: data = {
    accordianTitle: "Dating/Texting 101",
    accordianDesc: "",
    longTitle: "Dating/Texting 101",
    longDesc: [
        "While online dating has meant it’s super easy to connect with people you might have never met in the course of your normal life, it’s also meant that our first impression relies entirely on a simple text message.",
        "It can be daunting to work out how to craft that first message and keep the conversation rolling, while also making sure your personality comes across. That’s why we’re here to help! Below you’ll see a bit of a guide into how to dot the i’s and cross the t’s and make sure you’re well on your way to attracting that next date.",
    ],
    columns: [],
    HtmlEntity: <HtmlEntityDatingTextingData />,
    endPara: []
}


const handlingRejection: data = {
    accordianTitle: "Handling Rejection",
    accordianDesc: "",
    longTitle: "Handling Rejection",
    longDesc: [
        "You're not going to be into every match. Some promising ones can become mediocre, your dating priorities might change and even the nicest of dates might end up having no no chemistry with you.",
        "When that happens, it’s time to politely tell them you’re not interested in pursuing the connection any further. It might feel like you’re in the wrong but rejection is part of dating. People would rather be told to their face when you don’t want to pursue something with them than deal with the emotional burden of being ghosted.",
        "Here are our tips for handling rejection, from both sides of the table."
    ],
    columns: [],
    HtmlEntity: <HtmlEntityHandlineRejectionData />,
    endPara: []
}

const handlingToxicBehaviour = {
    accordianTitle: "Handling Toxic Behaviours",
    accordianDesc: "",
    longTitle: "Handling Toxic Behaviours",
    longDesc: [
        "Whilst we hope everyone is here to put their best foot forward, you may encounter toxic behaviour when dating. No matter what it is, you don’t have to tolerate anything that makes you feel uncomfortable."
    ],
    columns: [],
    HtmlEntity: <HtmlEntityHandlineToxicBehavioursData />,
    endPara: []
}

const TopFiveMistakes = {
    accordianTitle: "Top 5 mistakes",
    accordianDesc: "",
    longTitle: "Top 5 Mistakes When Dating",
    longDesc: [
        "According to Max Radcliffe"
    ],
    columns: [],
    HtmlEntity: <HtmlEntityTopFiveMistakesData />,
    endPara: []
}

export type { data }
export { Profile101, datingTexting101, handlingRejection, handlingToxicBehaviour, TopFiveMistakes }