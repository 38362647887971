import { IonImg } from '@ionic/react';
interface testLayout {
    heading: string,
    image: string,
    desc: string
}
const ExploreItems = ({ heading, image, desc }: testLayout) => {
    return (
        <div className="explore-special-col-2-layout">
            <div><IonImg src={image}></IonImg></div>
            <div>
                <h4>{heading} -</h4>
                <p>{desc}</p>
            </div>
        </div>
    );
}
const ExploreSpecialCols = () => {
    return (
        <div className="explore-special-col-2-layout-wrapper">
            <ExploreItems heading="Foodies" image="assets/img/au_explore/VectorSmartObject.png" desc="Looking For A Snack?" />
            <ExploreItems heading="Music Lovers" image="assets/img/au_explore/VectorSmartObject5.png" desc="Split Your Headphones" />
            <ExploreItems heading="Creatives" image="assets/img/au_explore/VectorSmartObject2.png" desc="Match Your Aesthetics" />
            <ExploreItems heading="Nature Lovers" image="assets/img/au_explore/VectorSmartObject3.png" desc="Discover The Outdoors" />
            <ExploreItems heading="Thrill Seekers" image="assets/img/au_explore/VectorSmartObject4.png" desc="Take The Leap With Someone" />
            <ExploreItems heading="" image="assets/img/au_explore/Andmanymore!.png" desc="" />
        </div>
    )
}

interface FlexInOurFeaturesCenterHeadingProps {
    title: string
}
const FlexInOurFeaturesCenterHeading = ({ title }: FlexInOurFeaturesCenterHeadingProps) => {
    return (
        <h3 className="flex-in-our-features-center-heading transparent-heading">{title}</h3>
    )
}
interface data {
    isActive?: boolean,
    accordianTitle: string,
    accordianDesc: string,
    longTitle: string,
    longDesc: string[],
    columns: {
        columnClass: string,
        columnHeading: string,
        columnImage: string,
        columnData: string[]
    }[],
    endPara: string[]
    HtmlEntity?: JSX.Element,

}


const TheProfileProject: data = {
    accordianTitle: "The Profile Project",
    accordianDesc: " ",
    longTitle: "The Profile Project",
    longDesc: ["So you’ve downloaded the app and popped in your details at sign up… but now what? Lowkey confused on what to include in your dating profile? Well, you’re not alone. To be at the top of your online dating game, the first step is to put together a profile that showcases the best and most authentic side of YOU 🤩. Channel your inner main character and give your Tinder profile the ultimate glow-up with these pro(file) tips 😉: "],
    columns: [
        {
            columnClass: "column-1",
            columnHeading: "#1: Get that bio right 💬",
            columnImage: "assets/img/au_the_profile_project_accordian/VectorSmartObject8.png",
            columnData: [
                "Whitty, cheeky or bubbly, your bio should be a quick explainer of your personality while also subtly hinting at your interests and unique qualities.",
                "■	Pro Tip: Pose a question to spark a meaningful conversation. For example, a fun question like ‘Euphoria: Overhyped or Overlooked?’, will always capture attention.",
                "Generic bios only attract mediocre matches. Which sounds better: ‘I’m a foodie’ or ‘On the hunt for the best cafes in Sydney, send through your reccos’? The latter and it even gives your potential matches an idea for a first date.",
                "Don’t leave your bio blank and aim to write more than just a few words. Whilst Tinder recommends keeping it punchy, don’t be afraid to be descriptive in your bio - turns out, 15-45 words is the optimum!"
            ]
        },
        {
            columnClass: "column-6",
            columnHeading: "#2: NGL, your photos matter 📸",
            columnImage: "assets/img/au_the_profile_project_accordian/VectorSmartObject7.png",
            columnData: [
                "In the world of online dating, a picture is worth a thousand words tons of matches! 🥵 The photos you pick can show what activities you like and your quirky personality in general. Starting a conversation online is much easier when all someone has to do is comment on your adorable dog or ask where a gorgeous photo was taken.",
                "We know the squad is important but maybe calm down on the group shots? Too many group pics are a dealbreaker and you don’t want to get lost in the crowd. Always remember: Your profile has to be about YOU! Plus solo pics are a great way to show off what a snacc you are. 😌",
                "Use a range of 4-5 photos which authentically show who you are and reveal your personality to potential matches,  just like your bio. And don’t be afraid to show your style,"
            ]
        },
        {
            columnClass: "column-1",
            columnHeading: "#3: Break the ice 🧊",
            columnImage: "assets/img/au_the_profile_project_accordian/VectorSmartObject6.png",
            columnData: [
                "Include an icebreaker in your profile! The decision of what to say in an opening message can sometimes be so paralysing that it could stop someone from messaging altogether. An icebreaker helps take the pressure off your matches and creates the right opportunity for them to reach out.",
                "Make it something fun and easy to answer, like ‘Which ice cream flavour is the vibe? Chocolate, Matcha, or Salted Caramel?’ or ‘Go-to hobby? Hitting the gym or visiting art galleries?’"
            ]
        },
        {
            columnClass: "column-6",
            columnHeading: "#4: A peek into your interests 🏕",
            columnImage: "assets/img/au_the_profile_project_accordian/VectorSmartObject5.png",
            columnData: [
                "Give your potential matches a peek into what spending a day with you would be like. Lay out your passions and favourite activities, and don’t forget to show off that fire personality of yours. 🔥",
                "Including them in your bio is a great opportunity to meet people with similar interests, or who just want to learn more - whether that’s how to paddle board, or if they can join your book club. They’re also a great conversation starter. ",
                "Pro tip: Don’t forget to use the ‘Interest’ feature to support your bio.",
            ]
        },
        {
            columnClass: "column-1",
            columnHeading: "#5: Share the love for music 🎶",
            columnImage: "assets/img/au_the_profile_project_accordian/VectorSmartObject4.png",
            columnData: [
                "You know you have impeccable music taste. Why not let others know too? Flex your tunes by sharing your favourite artists on your profile and connect with people who share your vibe. ",
                "You can even scope out your potential matches’ Spotify anthems and have yourself a new fav playlist in no time! ",
                "Pro tip: Discover Music Mode on Explore as you select your chosen music anthem for potential matches to vibe to your favourite songs!",
            ]
        },
        {
            columnClass: "column-6",
            columnHeading: "#6: Be a verified QT",
            columnImage: "assets/img/au_the_profile_project_accordian/VectorSmartObject3.png",
            columnData: [
                "Getting your profile verified is a great way to ensure that not only will you get legit matches, but you’ll likely get more matches than if you don’t! Having a verified profile automatically makes you easier to approach as well, who’s got the time to second guess whether a match is who they say they are."
            ]
        },
        {
            columnClass: "column-1",
            columnHeading: "#7 Switch things up",
            columnImage: "assets/img/au_the_profile_project_accordian/VectorSmartObject2.png",
            columnData: [
                "Keep your bio fresh and relevant - perhaps you change it to mention your new favourite TV show, or the best new hotspot in town for a date."
            ]
        },
        {
            columnClass: "column-6",
            columnHeading: "#8 Be open and honest about what you’re looking for",
            columnImage: "assets/img/au_the_profile_project_accordian/VectorSmartObject.png",
            columnData: [
                "While a long check-list of dream qualities is a tad overkill, using Tinder’s Relationship Goals feature enables you to tell a match what you’re looking for   and helps set the foundations from the beginning. If you’re not quite sure what you’re looking for yet, that’s ok.",
                "Use Tinder’s Relationship Goals and/or Relationship Type features to support what you write in your bio.",
            ]
        }
    ],
    endPara: [
        "When you keep it fun, light and most importantly, authentically YOU, you’re bound to score full marks in this project (along with some super cool matches 😉). With these tips, your profile is going to be an easy Swipe Right™. So, what are you waiting for? Go put the Pro in your profile."
    ]

}

const AvoidDryConvos: data = {
    accordianTitle: "Avoid Dry Convos",
    accordianDesc: " ",
    longTitle: "How To Avoid Dry Convos",
    longDesc: [
        "We’ve all received the typical, “Hey, how’s it going?”, opening line and TBH we’ve all caved in and sent it as well. 😬 Don’t lie. Of course, we all want to make a great first impression and start off strong AKA hitting the holy trinity - originality, creativity and hilarity. But overthinking gets overwhelming and, suddenly, the safest option starts looking like the best option.",
        "The only problem here is that you could come off a bit dry which is the last thing you want - getting ghosted because of a bad opener is never fun. Dry texts lead to dead ends so generic conversation starters are hereby, cancelled.",
        "Keep your initial text simple yet personalised to maximise your chances of a response. Don’t know where to begin? 🤔 Check out these tips to keep your texting game on point :"
    ],
    columns: [
        {
            columnClass: "column-1",
            columnHeading: "#1: Address them by their name",
            columnImage: "assets/img/au_avoid_dry_convos/emoji-02.png",
            columnData: [
                "The simplest way to let someone know you’re paying attention to them and their profile is to use their name in your opening message. It’s personal and people tend to appreciate that. 😌",
                "Pro Tip: Always double-check the spelling of their name! Typos are always bad but when it comes to misspelling a name, there’s no going back."
            ]
        },
        {
            columnClass: "column-6",
            columnHeading: "#2: Don’t skim over their interests",
            columnImage: "assets/img/au_avoid_dry_convos/emoji-08.png",
            columnData: [
                "If they’ve mentioned something they like in their profile, ask them a question about it! This is a great way to find some common ground and skip any initial awkwardness.",
                "Example: “Hi Chantalle! I noticed that you like Mayday Parade. They’re one of my favourite bands! What’s your favourite song?”"
            ]
        },
        {
            columnClass: "column-1",
            columnHeading: "#3: Be inquisitive!",
            columnImage: "assets/img/au_avoid_dry_convos/emoji-19.png",
            columnData: [
                "There’s no better way to get to know someone than to ask them more about themselves, but avoid making the convo all current affairs or your latest binge-watch. Focus on topics that allow you to understand more about your match and to foster your connection on a deeper level. Ask questions and don’t be afraid to show your interest in your match! 🥰",
                "Example: “What’s your go-to weekend like?”",
                "Not everyone gets the memo that filling out your bio is important. If you stumble upon someone irresistible but there’s no natural icebreaker to inspire your opening line, these tips can do you wonders to get the conversation going 💬 :"
            ]
        },
        {
            columnClass: "column-6",
            columnHeading: "#1: No bio? Call them out (nicely, of course)",
            columnImage: "assets/img/au_avoid_dry_convos/emoji-16.png",
            columnData: [
                "Instead of lingering in silence, send them a cheeky message: “Why so mysterious?” and see if they can fill the void. 😉",
            ]
        },
        {
            columnClass: "column-1",
            columnHeading: "#2: Play two truths, one lie",
            columnImage: "assets/img/au_avoid_dry_convos/emoji-15.png",
            columnData: [
                "When there’s no info on them to go off, you could offer some on yourself. Throw in a lie and two facts about yourself and ask your match to guess which one is the truth. The intrigue is bound to pull them in and knowing more about you would definitely make them more comfortable with sharing!",
            ]
        },
        {
            columnClass: "column-6",
            columnHeading: "#3: Compliment their style",
            columnImage: "assets/img/au_avoid_dry_convos/emoji-05.png",
            columnData: [
                "People always appreciate compliments on a personal choice - their haircut, fashion sense or interests - over a compliment on their looks. Love their fit? Drop them a line and let them know you like their style. 😎",
                "Example: “Loving your sunglasses, where are they from?” "
            ]
        },
    ],
    endPara: [
        "It’s always important to keep the conversation flowing along with the chemistry! We know these are the basics but, anytime you match with a cutie and can’t seem to crack the chat, head back to this page to level up your approach and get some advice on how to break the ice. 🔥"
    ]

}

const Explore: data = {
    accordianTitle: "Explore",
    accordianDesc: " ",
    longTitle: "Experts Explain “Explore”",
    longDesc: [
        "There may be “plenty of fish in the sea,” but where exactly do you begin when the sea is HUGE? 🤔 No, that’s not us being extra. Such a big crowd could leave you feeling like a fish out of water, but this is where Tinder’s Explore feature comes in.",
        "Through Explore, we help you navigate the sea and narrow down YOUR pool, making it easier for you to find the fish you vibe with!",
    ],
    columns: [
        {
            columnClass: "column-1",
            columnHeading: "What is Explore?",
            columnImage: "assets/img/au_explore/VectorSmartObject7.png",
            columnData: [
                "Tinder’s lit in-app venue, Explore, lets you spark new connections through common interests and activities, making it the perfect way for you to find your tribe."
            ]
        },
        {
            columnClass: "column-6 explore-special-col-1",
            columnHeading: "Why Explore?",
            columnImage: "assets/img/au_explore/VectorSmartObject6.png",
            columnData: [
                "It’s a fun, interactive space built to be the perfect area for you to explore (😉) new social experiences.",
                "It is centred around categories and various activities to give you more control over who you meet and what they’re into.",
                "It helps you connect with new, like-minded people over common goals and shared interests.",
            ]
        },
        {
            columnClass: "column-1 text-center",
            columnHeading: "",
            columnImage: "",
            columnData: [
                "With Explore, you can discover potential matches for every mood, activity and vibe, such as:"
            ]
        },

    ],
    HtmlEntity: <ExploreSpecialCols />,
    endPara: [
        "Dying to explore Explore? Simply open your Tinder app and click on the second icon from the left at the bottom of the page. You’re just 2 taps away from a whole new world of possibilities and sizzling matches!🔥"
    ]

}


const FlexInOurFeatures: data = {
    accordianTitle: "Flexin' our Features",
    accordianDesc: " ",
    longTitle: "Flexin’ Our Features",
    longDesc: [
        "Not to brag but we found a couple of ways to make meeting people on Tinder even more fun. Okay, we’re totally bragging (we know you can relate😏). The same way you curate your profile to show off your best angles, we wanted to flex some of Tinder’s best features that add some pizzazz to your matching experience.",
        "Keep reading to know more about our top features that make your Tinder experience stand out - like that one match you can’t get out of your head! 😉"
    ],
    columns: [
        {
            columnClass: "column-1",
            columnHeading: "#1 Block Contacts",
            columnImage: "assets/img/au_flexin_our_features/VectorSmartObject4.png",
            columnData: [
                "When you get excited meeting others on Tinder, coming across a toxic ex is the last thing you want. 😬  If you want to avoid seeing certain people on Tinder, our Block Contacts feature has got you covered. Simply share your contact list and whether those contacts are already on Tinder or decide to download it later using the same contact info, they won’t ever appear as a potential match."
            ]
        },
        {
            columnClass: "column-6 explore-special-col-1",
            columnHeading: "#2 Block Profile",
            columnImage: "assets/img/au_flexin_our_features/VectorSmartObject3.png",
            columnData: [
                "Tinder is a surefire way to connect with people you’d never usually cross paths with, but every once in a while you still might see someone you already know. Whether it’s a boss or a family member, there’s not many quicker turn offs when you’re enjoying your time on Tinder. Our Block Profile feature helps you avoid that awkward pause as people from your life sneak into your potential matches, letting you block a profile so that they don’t show up again."
            ]
        },
        {
            columnClass: "column-1 text-center",
            columnHeading: "#3 Photo Verification",
            columnImage: "assets/img/au_flexin_our_features/VectorSmartObject2.png",
            columnData: [
                "As much as we don’t love seeing fish pics on dating profiles, the worst kind of fish to ever deal with on Tinder is a catfish. Our Photo Verification feature helps show your photos are really you by comparing profile photos with a series of posed photos taken in-app in real time. It’s as simple as taking some in-app selfies that are then compared with your profile pictures before you get Verified! Within their Message Settings, Photo Verified users can also opt to only receive messages from other Photo Verified users."
            ]
        },
        {
            columnClass: "column-6 explore-special-col-1",
            columnHeading: "#4 Video Selfie",
            columnImage: "assets/img/au_flexin_our_features/VectorSmartObject.png",
            columnData: [
                "Video selfie takes Photo Verification to the next level. Until now, users would take still photos while holding a series of static poses, and these photos were compared against others on the users’s profile. Now, if users want to get photo verified they will have to complete a series of video prompts."
            ]
        },
        {
            columnClass: "column-1 text-center",
            columnHeading: "#1 Passport",
            columnImage: "assets/img/au_flexin_our_features/plane.png",
            columnData: [
                "Before you decide to meet a match, it takes a while for a connection to be made, conversations to be had and a rapport to be built. This gets extra tricky when you travel a lot. With our Passport feature, you can match anyone from anywhere in the world so that by the time you reach your destination, you’re ready to catch up with that new cutie! 🌏"
            ]
        },
        {
            columnClass: "column-6 explore-special-col-1",
            columnHeading: "#2 Super Like ",
            columnImage: "assets/img/au_flexin_our_features/superlike.png",
            columnData: [
                "Have you ever come across someone that’s completely irresistible and a simple Swipe RightTM just wouldn’t be enough? Tell them how you feel when you Super Like™ them! 🤩 Just tap the blue star icon to let them know you’re simping. Your profile will appear in their stack with a blue border and star, highlighting your interest."
            ]
        },
        {
            columnClass: "column-1 text-center",
            columnHeading: "#3 Boost",
            columnImage: "assets/img/au_flexin_our_features/s.png",
            columnData: [
                "If you haven’t been having much luck meeting others, this is the best time to activate Boost. When you use a Boost, your profile shoots up to the top so that you can come across more potential matches in your area! 🚀 Tap on the purple lightning bolt for a 30 min long boost and watch how you get up to 10x more profile views, more matches and more lit connections."
            ]
        },

    ],
    endPara: [
        "Online dating is already exciting as it is but there’s nothing more fun than getting onto Tinder with all these options at your disposal. Stay tuned for more features or spend some time getting to know the ones you haven’t used yet to level up your Tinder game! 🔥"
    ]

}



export type { data }
export { TheProfileProject, AvoidDryConvos, Explore, FlexInOurFeatures }