import { IonImg } from '@ionic/react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { createContext, useContext } from 'react';

type PdfContextType = {
    modalVisible: boolean;
    desktopPdf: string;
    mobilePdf: string;
    setDesktopPdf: (pdf: string) => void;
    setMobilePdf: (pdf: string) => void;
    setModalVisible: (visible: boolean) => void;
}

export const PdfContext = createContext({
    modalVisible: false,
    desktopPdf: '',
    mobilePdf: '',
    setModalVisible: () => { },
    setDesktopPdf: () => { },
    setMobilePdf: () => { }
} as PdfContextType);

export function PdfViewer() {
    const { modalVisible, desktopPdf, mobilePdf, setModalVisible } = useContext(PdfContext);
    return (
        <div className={`pdf-viewer ${modalVisible === true ? 'open' : ''}`}>
            <div className="btn-holder">
                <div>
                    <IonImg className='download-btn hide-desktop' src='assets/img/download.png' onClick={() => window.open(mobilePdf)}></IonImg>
                    <IonImg className='download-btn hide-mobile' src='assets/img/download.png' onClick={() => window.open(desktopPdf)}></IonImg>
                    <IonImg className='close-btn' src='assets/img/close-btn.png' onClick={() => setModalVisible(false)}></IonImg>
                </div>
            </div>
            <div>
                <Document file={desktopPdf} className="hide-mobile">
                    <Page pageNumber={1} height={1600}/>
                </Document>
                <Document file={mobilePdf} className="hide-desktop">
                    <Page pageNumber={1} height={1600} />
                </Document>
            </div>
        </div>
    );
}