import { useEffect, useRef } from "react";
import { IonImg } from '@ionic/react';
import { data } from "../AppAcademyData";

const AccordianItems = ({ accordianTitle, accordianDesc, longTitle, longDesc, columns, endPara, HtmlEntity, isActive }: data) => {
    const htmlEntityRef = useRef(null);
    useEffect(() => {
        if (htmlEntityRef && htmlEntityRef.current && typeof HtmlEntity === 'string') {
            (htmlEntityRef.current as HTMLElement).innerHTML = HtmlEntity;
        }
    }, []);
    return (
        <>
            <div className="accordian-item">
                <button className={`accordian-heading ${isActive && "active"}`} onClick={(e) => { e.currentTarget.classList.toggle("active"); e.currentTarget.scrollIntoView() }}>
                    <div className='accordian-item-heading-wrapper'>
                        <h2>{accordianTitle}</h2>
                        {/* <p>{accordianDesc}</p> */}
                    </div>
                </button>
                <div className="accordian-details">
                    <div className="first-class-first-date-data bg-transparent">
                        <h3>{longTitle}</h3>
                        <div>
                            {longDesc.map(desc => <p>{desc}</p>)}
                        </div>
                    </div>
                    <div className="columns">
                        {
                            columns.map((item, index) => {
                                return (
                                    <div key={index}>
                                        {longTitle == "Flexin’ Our Features" && index === 4 ? <h3 className="flex-in-our-features-center-heading transparent-heading">Premium Features</h3> : null}

                                        <div className={`column-default ${item.columnClass}`}>
                                            {item.columnImage && <div className="column-img"><IonImg src={item.columnImage} /></div>}
                                            <h2>{item.columnHeading}</h2>
                                            <div>
                                                {item.columnData.map((colData, index) => <p>{colData}</p>)}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {HtmlEntity ? HtmlEntity : null}
                        {
                            endPara.length > 0 &&
                            <div className="padding max-container-size">
                                {
                                    endPara.map((para, index) => <p key={index} className='padding text-center'>{para}</p>)
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
// AccordianItems.defaultProps = FlexInOurFeatures;
export default AccordianItems;