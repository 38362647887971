interface data {
  isActive?: boolean;
  accordianTitle: string;
  accordianDesc: string;
  longTitle: string;
  longDesc: string[];
  columns: {
    columnClass: string;
    columnHeading: string;
    columnImage: string;
    columnData: string[];
  }[];
  endPara: string[];
  HtmlEntity?: JSX.Element;
}

const firstClassFirstDate: data = {
  accordianTitle: "First Class: First Dates",
  accordianDesc:
    "Kiss those nerves goodbye with these first date tips that never fail!",
  longTitle: "First Class: First Dates",
  longDesc: [
    "POV: You’re getting ready for a first date (What to wear? Which perfume to use? The list goes on…) and suddenly, the nerves hit you like a ton of bricks. Rest assured, it happens to everyone, even to a catch like yourself. 🥵",
    "Just remember: The more you’re in your head, the less connected you are to the present moment. So don’t ever let that deter you from having fun! Follow these tips to calm those pesky first date jitters:",
  ],
  columns: [
    {
      columnClass: "column-1 padding",
      columnHeading: "Mind over Matter",
      columnImage:
        "assets/img/au_first_class_first_dates/au_Vector_Smart_Object_4.png",
      columnData: [
        "Nerves aren't necessarily a bad thing! You’re probably just really excited to meet the cool person that you connected with so well online. Understanding why it's getting you so worked up would be the first step to acknowledging your emotions and helping you accept them. Once you do that, you can finally shake it off, see the bigger picture, be confident and get out there! 👏",
      ],
    },
    {
      columnClass: "column-6",
      columnHeading: "We’re all in this together",
      columnImage:
        "assets/img/au_first_class_first_dates/au_Vector_Smart_Object_2.png",
      columnData: [
        "You’re not alone -  your date is probably freaking out as much as you are! Everyone has the fear of getting rejected or leaving a bad first impression. Remembering that first dates force everyone to put themselves out there can be normalizing and grounding. We’re all human after all. Try to make the other person feel as comfortable as possible and who knows where it might lead? It might be the start of something special. 😉",
      ],
    },
    {
      columnClass: "column-1",
      columnHeading: "Meet me at our spot",
      columnImage:
        "assets/img/au_first_class_first_dates/au_Vector_Smart_Object_3.png",
      columnData: [
        "Being involved in planning the date can help your anxiety take a chill pill even if it might be a little taxing. Pick the venue and choose a place you’re familiar with! This can eliminate potentially stressful aspects that come with unfamiliar territory. You already know what to order at your fave restaurant or how good the coffee is at the one cute cafe in the city so that’s one less thing to worry about! 🤩",
      ],
    },
    {
      columnClass: "column-6",
      columnHeading: "Good ~vibes~ only",
      columnImage:
        "assets/img/au_first_class_first_dates/au_Vector_Smart_Object.png",
      columnData: [
        "Being in a positive mood and having fun is a 10/10 way to calm your nerves. The vibes are key. Plan a fun activity for the date like mini golf or visiting a local art gallery so that you have something to talk about if you’re both art lovers. Whatever you do, you don’t want the date to get boring. To boost your mood even further, create a feel-good Spotify playlist that you can listen to whilst getting ready! 💃",
      ],
    },
  ],
  endPara: [
    "Just remember: first dates can be anxiety-inducing but they’re also the start of something potentially amazing so c’mon, focus on having fun! Shake off your worries, put on that cute fit and go be the awesome person your date matched with. 🤌",
  ],
};

const Disclamer = () => {
  return (
    <div className="irl_dating_on_trend_disclamer">
      *Data sourced from Tinder Future of Dating Report 2023 <br />
      <br />
      †Statistics from a Tinder survey conducted by One Poll in April 2022 of
      18-25 year olds
    </div>
  );
};
const IRLDatingOnTrend: data = {
  accordianTitle: "IRL Dating on Trend",
  accordianDesc: "Lorem ipsum dolor sit amet",
  longTitle: "Dating on Trend",
  longDesc: [
    "So there you stand, about to ask your match on a date, the biggest leap of faith that you’ll take today… Okay maybe it’s not quite that dramatic but you get where we’re coming from.",
    "Coming up with a solid date idea can definitely amp up the pressure in a situation where many of us are already feeling the butterflies and nerves as we look to make our best first impression on someone we’re really excited to meet.",
    "The great news is, there are plenty of on-trend date ideas that will help you put together a fantastic first date and remind your date why they matched with you in the first place! This module tackles some of the top trends uncovered in Tinder’s Future of Dating Report, so you know you’re on the tried and tested path when using this as inspo for that cute date.",
  ],
  columns: [
    {
      columnClass: "column-1 padding",
      columnHeading: "Ditch the daiquiris",
      columnImage: "assets/img/au_IRL_dating_on_trend/VectorSmartObject5.png",
      columnData: [
        "This might come as a surprise, but the stereotypical first date activity of meeting up for drinks is actually starting to decline. A whopping 88% of singles we surveyed told us they were interested in going on a sober date this year.",
        "So, what’s on the menu instead of cocktails? Mini golf has polled surprisingly well, with the added competitive element almost impossible to resist for many singles. Outdoor concerts and movies are also popular go-to’s, especially in the warmer months, while activities like attending a ceramics class can be a great, creative date idea which is perfect for the indoors.",
      ],
    },
    {
      columnClass: "column-6",
      columnHeading: "Get grounded",
      columnImage: "assets/img/au_IRL_dating_on_trend/VectorSmartObject2.png",
      columnData: [
        "Good mental health is as sexy a trait as any when it comes to what Gen Z are after, and 39%  named working on personal growth and wellbeing as their #1 priority, with close to 75% of young singles saying they find a match more attractive if they’re open to or currently working on their mental wellbeing*.",
        "Now, we’re not suggesting you go to therapy together as a first date, but there’s a lot of other great ways to show that you’re grounded. Choose an activity that offers you the best chance to actually talk to your date and show them who you are and what you’re about.",
        "The humble picnic date ranks high on the date wishlist of singles*, not only giving you a chance to get to know each other in a less intense situation but also giving you some glorious time to bask in the sunlight and soak up the great outdoors. If you really want to take it to the next level you can even work in some meditation or manifestation exercises, building a bond over the shared wellness experience.",
      ],
    },
    {
      columnClass: "column-1 padding",
      columnHeading: "Multitasking mania",
      columnImage: "assets/img/au_IRL_dating_on_trend/VectorSmartObject.png",
      columnData: [
        "When Dolly Parton wrote 9-5, online dating hadn’t even been invented yet and it was still tough for people to find a balance. Between work, university and other social commitments, it can be hard to find the time to commit to going on a date. Rest assured, you’re not the only one dealing with this predicament.",
        "Enter stack dating, the newest dating trend that allows you to get sh*t done! Need to get a birthday present for a parent? Grab it before your movie date at the local cinema. Haven’t had a chance to pick up your package from the post office? Organise a coffee and walk date going past so that you can stop by to collect. There’s enough going on in our daily lives, so it’s time to make dating work for you.",
      ],
    },
    {
      columnClass: "column-6 padding",
      columnHeading: "The world is your oyster",
      columnImage: "assets/img/au_IRL_dating_on_trend/VectorSmartObject3.png",
      columnData: [
        "Tinder’s whole raison d’être is to allow you to cast the net wider than would ever be possible by just dating within your network. We know that a meaningful connection can come from anywhere and, it turns out, so do Tinder members. Two thirds of people we spoke to said they’ve met someone on the app they would never have met otherwise in their day to day lives*.",
        "So, how does this translate into practice? Step one is finding someone you might have never met. Whether it’s increasing the distance you set your search to, or jumping into Passport to meet people from different cities or even countries, it’s never been easier to find a new connection.",
        "Don’t shy away from the virtual date! Tinder offers an in-app video call feature that’s perfect for kicking things off with a virtual video gaming session. You might also be able to watch a TV show or movie together with a virtual watch party.",
      ],
    },
    {
      columnClass: "column-1 text-right",
      columnHeading: "Flirting frugally",
      columnImage: "assets/img/au_IRL_dating_on_trend/VectorSmartObject4.png",
      columnData: [
        "Love might not cost a thing, but the price of a date has certainly gone up in recent times. Making an impression on your date shouldn’t break the bank. It’s no secret that many singles are feeling the pinch financially so don’t feel like this is something that only affects you.",
        "The humble coffee date gets the co-sign from two thirds of young daterss, sharing that their ideal date is going for a walk or grabbing a coffee†. Not only is this going to keep your wallet fuller for longer, it’s also much easier to get to know a date than trying to talk over the decibels of a bar’s sound system.",
        "Coffee not cutting it for you? Australia’s great outdoors offer an impossibly diverse range of experiences to light up any date. Some of our favourite ideas include a hike (waterfall destination optional) or a sunny beach date.",
      ],
    },
  ],
  HtmlEntity: <Disclamer />,
  endPara: [],
};

export type { data };
export { firstClassFirstDate, IRLDatingOnTrend };
