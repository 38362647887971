import { IonGrid, IonRow, IonHeader, IonToolbar, IonImg } from '@ionic/react';
import { useRef, useState, useEffect } from 'react';
import IRLDating from '../../components/au/tabs/IRLDating/IRLDating';
import AppAcademy from '../../components/au/tabs/AppAcademy/AppAcademy';
import Tabs from '../../components/au/tabs/Tabs/Tabs';
import AccordianItems from '../../components/au/tabs/AccordianItems/AccordianItems';
import { TheProfileProject, AvoidDryConvos, Explore, FlexInOurFeatures } from '../../components/au/tabs/AppAcademyData';
import { firstClassFirstDate, IRLDatingOnTrend } from '../../components/au/tabs/IRLDatingData';
import { SafetySeminar, ConsentCrashCourse } from '../../components/au/tabs/SaftySyllabusData';
import { Profile101, datingTexting101, handlingRejection, handlingToxicBehaviour, TopFiveMistakes } from '../../components/au/tabs/DontBeAnIckData';
import { datingDictionary, consentEdition } from '../../components/au/tabs/DatingDictionaryData';
import { Parallax } from "react-scroll-parallax"
import { useParallaxController } from 'react-scroll-parallax';
const Au = () => {
    const [activeTab, setActiveTab] = useState(0);
    const bgWrapper = useRef<HTMLDivElement>(null);
    const ribbonTabRef = useRef<HTMLDivElement>(null);
    // const parallaxController = useParallaxController();

    // useEffect(() => {
    //     parallaxController?.update();
    // }, []);

    const appAcademyTabData = {
        tabname: "App Academy",
        tabDesc: ["The App Academy makes sure you know your way around Tinder just as well as you know your way around the heart. It walks you through all the cool features that you may have missed (yup, liking and matching from a different country is totally a thing) along with a tour of our hottest new in-app venue - Explore. After browsing through this section, don’t be surprised if you’re attracting matches from every direction!"],
        accordianItems: [
            <AccordianItems {...TheProfileProject} isActive={true} />,
            <AccordianItems {...AvoidDryConvos} />,
            <AccordianItems {...Explore} />,
            <AccordianItems {...FlexInOurFeatures} />
        ]
    };
    const irlDatingTabData = {
        tabname: "IRL Dating",
        tabDesc: [
            "This one's for all of you who cracked the code to online dating but then low-key freak out when it starts shifting offline.",
            "Here's an easy walkthrough on how to make the smooth transition from online to IRL in a chill, anxiety-free way!",
        ],
        accordianItems: [
            <AccordianItems {...firstClassFirstDate} isActive={true} />,
            <AccordianItems {...IRLDatingOnTrend} />,
        ]
    }
    const SafetySyllabus = {
        tabname: "Safety Syllabus",
        tabDesc: [
            "Vibing with new people is always exciting but only when you put your safety first! Check out this all-in-one guide on how to make the most of your matching experience while also staying careful, both online and offline. We’ve partnered with consent advocate and author, Chanel Contos, to cover the ABC’s of safety (Awareness, Boundaries & Consent!) along with so much more."
        ],
        accordianItems: [
            <AccordianItems {...SafetySeminar} isActive={true} />,
            <AccordianItems {...ConsentCrashCourse} />,
        ]
    }
    const dontBeAnIck = {
        tabname: "Don't Be An Ick",
        tabDesc: [
            "It might be biased, but online dating is easily one of the most exciting things that technology has enabled us to do as it’s evolved. The possibilities are quite literally endless, and you never know where a connection made on Tinder might lead.",
            "At the same time though, we know that understanding the nuances and the unwritten rules of online dating can be difficult. How do you know for sure that it’s okay to double text? What do you do when someone won’t message you back after you’ve finally gone on a date with them?",
            "To make it all easy for you, we’ve worked with Max Radcliffe, an expert on respectful relationships, to prepare a resource that should help you to navigate the ins and outs of dating respectfully. We hope that this syllabus will make sure you avoid that most unbearable feeling of becoming someone’s ICK."
        ],
        accordianItems: [
            <AccordianItems {...Profile101} isActive={true} />,
            <AccordianItems {...datingTexting101} />,
            <AccordianItems {...handlingRejection} />,
            <AccordianItems {...handlingToxicBehaviour} />,
            <AccordianItems {...TopFiveMistakes} />,
        ]
    }
    const DatingDictionary = {
        tabname: "Dating Dictionary",
        tabDesc: [],
        accordianItems: [
            <AccordianItems {...datingDictionary} isActive={true} />,
            <AccordianItems {...consentEdition} />,
        ]
    }
    const tabToComponentMaping = [
        {
            title: "App Academy",
            comp: <Tabs {...appAcademyTabData} />
        },
        {
            title: "IRL Dating",
            comp: <Tabs {...irlDatingTabData} />
        },
        {
            title: "Safety Syllabus",
            comp: <Tabs {...SafetySyllabus} />
        },
        {
            title: "Don't Be An Ick",
            comp: <Tabs {...dontBeAnIck} />
        },
        {
            title: "Dating Dictionary",
            comp: <Tabs {...DatingDictionary} />
        },
    ];

    useEffect(() => {
        if (bgWrapper.current) {
            for (let i = 0; i < 15; i++) {
                const divElement = document.createElement("div"); // Create a new div element
                const randomClassTriangle = Math.floor(Math.random() * 4) + 1;
                divElement.classList.add(`trangle-${randomClassTriangle}`);
                const randomPositionX = Math.floor(Math.random() * 101);
                const randomPositionY = Math.floor(Math.random() * 101);
                divElement.style.left = `${randomPositionX}%`;
                divElement.style.top = `${randomPositionY}%`;
                bgWrapper.current.appendChild(divElement);
            }
        }
    }, []);

    useEffect(() => {
        document.body.classList.add('sos-au-page'); // add sos-au-page class to body

        return () => {
            document.body.classList.remove('sos-au-page'); // remove sos-au-page class from body
        }
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const ribbonTabRefElement = ribbonTabRef.current;
            if (ribbonTabRefElement) {
                const rect = ribbonTabRefElement.getBoundingClientRect();
                if (window.scrollY >= rect.top + rect.height) {
                    ribbonTabRefElement.classList.add('ribbon-fixed');
                } else {
                    ribbonTabRefElement.classList.remove('ribbon-fixed');
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [ribbonTabRef]);

    return (
        <main className='sos-au-page' style={{ height: '100%', overflow: 'scroll' }}>
            <div className='au-page-background' style={{ height: '100%', overflow: 'scroll' }}>
                <div className='bg-wrapper' ref={bgWrapper}>
                    <div className='trangle-1'></div>
                    <div className='trangle-2'></div>
                    <div className='trangle-3'></div>
                    <div className='trangle-4'></div>
                </div>
                <IonHeader className="ion-no-border ion-padding ion-justify-content-center" mode="md">
                    <IonToolbar>
                        <IonGrid>
                            <IonRow className="ion-justify-content-between nav-logos">
                                <div>
                                    <IonImg src="assets/img/au_tinder_white_logo.png" className='header-logo ion-padding' onClick={() => { window.location.href = "/" }}></IonImg>
                                </div>
                                <div>
                                    <IonImg src="assets/img/au_SoS_logo.png" className='header-logo ion-padding' onClick={() => { window.location.href = "/" }}></IonImg>
                                </div>
                            </IonRow>
                        </IonGrid>
                    </IonToolbar>
                </IonHeader>
                <section>
                    <div className='heroContainer'>
                        <IonImg className='heroCircleText' src="assets/img/au_swipe_circle.png" />
                        <IonImg className='heroPeople' src="assets/img/au_people.png" />
                    </div>
                </section>
                <div className='max-container-size'>
                    <section className='cta'>
                        <h1>What&apos;s the hype around<br />School of Swipe™?</h1>
                        <p>
                            With all the sliding, matching, ghosting, thirsting and so much more that goes in on the world of dating, there&apos;s a new  lesson around every corner. Get ahead of the curve and level up your skills so that you&apos;re fully prepped to hit up that cutie.
                        </p>
                        <p>
                            Need a dating lesson? Class is in session.
                        </p>
                        <button className='cta-button'>Let&rsquo;s go</button>
                    </section>
                </div>
                <section>
                    <div ref={ribbonTabRef} className='ribbon'>
                        <div>
                            {tabToComponentMaping.map((item, index) => <button key={index} className={index === activeTab ? "active" : undefined} onClick={() => setActiveTab(index)}>{item.title}</button>)}
                        </div>
                    </div>
                    {
                        tabToComponentMaping[activeTab].comp
                    }
                </section>
            </div>
        </main>

    )
}

export default Au;